<template
  scrollable
>
  <div
    id="create-account"
  >
    <v-form
      ref="form"
      v-model="valid"
      :lazy-validation="lazy"
    >
      <v-row>
        <v-col>
          <p style="margin-top: 2px"><b>User Name</b></p>
          <v-text-field
            v-model="userName"
            :rules=[checkUserNameRule]
            name="username"
            class="login-input"
            @change="checkUserName"
            placeholder="Not case sensitive"
            required
            outlined
            :readonly="confirmationCodeRequested"
          />
        </v-col>
        <!-- <v-col
          :md="screenTypeSmall ? '12' : '6'"
        >
          <p style="margin-top: 2px">
            <b>Promo Code</b>
            <span
            v-if = "isInvalidPromo"
            style="float: right; color: red; font-size: 15px"
            >
            Invalid Promo Code!
            </span>
          </p>
          <v-text-field
            v-model="promoCode"
            name="promocode"
            class="login-input"
            @blur="isPromoCodeValid"
            required
            outlined
            :readonly="confirmationCodeRequested"
            :maxlength="10"
          />
        </v-col> -->
      </v-row>
      <!-- <v-row
        v-if="screenTypeSmall"
      >
        <v-col
          md="6"
        >
          <p style="margin-top: 2px"><b>User Name</b></p>
          <v-text-field
            v-model="userName"
            :rules=[checkUserNameRule]
            name="username"
            class="login-input"
            @change="checkUserName"
            placeholder="Not case sensitive"
            required
            outlined
            :readonly="confirmationCodeRequested"
          />
        </v-col>
      </v-row>
      <v-row
        v-if="screenTypeSmall"
      >
        <v-col
          md="6"
        >
          <p style="margin-top: 2px">
            <b>Promo Code</b>
            <span
            v-if = "isInvalidPromo"
            style="float: right; color: red; font-size: 15px"
            >
            Invalid promo code!
            </span>
          </p>
          <v-text-field
            v-model="promoCode"
            name="promocode"
            class="login-input"
            @blur="isPromoCodeValid"
            required
            outlined
            :readonly="confirmationCodeRequested"
            :maxlength="10"
          />
        </v-col>
      </v-row> -->
      <v-row
        v-if="!screenTypeSmall"
      >
        <v-col
          :md="screenTypeSmall ? '12' : '6'"
        >
          <p><b>First Name</b></p>
          <v-text-field
            v-model="firstName"
            :rules="firstNameRules"
            name="firstname"
            class="login-input"
            required
            outlined
            :readonly="confirmationCodeRequested"
          />
        </v-col>
        <v-col
          :md="screenTypeSmall ? '12' : '6'"
        >
          <p><b>Last Name</b></p>
          <v-text-field
            v-model="lastName"
            :rules="lastNameRules"
            name="lastname"
            class="login-input"
            required
            outlined
            :readonly="confirmationCodeRequested"
          />
        </v-col>
      </v-row>
      <v-row
        v-if="screenTypeSmall"
      >
        <v-col
          :md="screenTypeSmall ? '12' : '6'"
        >
          <p><b>First Name</b></p>
          <v-text-field
            v-model="firstName"
            :rules="firstNameRules"
            name="firstname"
            class="login-input"
            required
            outlined
            :readonly="confirmationCodeRequested"
          />
        </v-col>
      </v-row>
      <v-row
        v-if="screenTypeSmall"
      >
        <v-col
          :md="screenTypeSmall ? '12' : '6'"
        >
          <p><b>Last Name</b></p>
          <v-text-field
            v-model="lastName"
            :rules="lastNameRules"
            name="lastname"
            class="login-input"
            required
            outlined
            :readonly="confirmationCodeRequested"
          />
        </v-col>
      </v-row>
      <v-row
        v-if="screenTypeSmall"
        class="mt-0"
      >
        <v-col
          :md="screenTypeSmall ? '12' : '6'"
        >
          <p><b>Email ID</b></p>
          <v-text-field
            v-model="email"
            :rules="emailRules"
            name="email"
            class="login-input"
            required
            outlined
            :readonly="confirmationCodeRequested"
          />
        </v-col>
      </v-row>
      <v-row
        v-if="screenTypeSmall"
        class="mt-0"
      >
        <v-col
          md="6"
        >
          <p><b>Phone (optional)</b></p>
            <vue-country-code
              style="display: flex; float: left;"
              @onSelect="setCountryCode"
              :preferredCountries="['us']"
            >
            </vue-country-code>
            <v-text-field
              v-model="phoneNumber"
              name="phonenumber"
              :rules="phoneRules"
              @keypress="isNumber($event)"
              :maxlength="12"
              class="login-input"
              placeholder="XXX-XXX-XXXX"
              required
              outlined
              :readonly="confirmationCodeRequested"
            />
        </v-col>
      </v-row>
      <v-row
        v-if="!screenTypeSmall"
        class="mt-0"
      >
        <v-col
          :md="screenTypeSmall ? '12' : '6'"
        >
          <p><b>Email ID</b></p>
          <v-text-field
            v-model="email"
            :rules="emailRules"
            name="email"
            class="login-input"
            required
            outlined
            :readonly="confirmationCodeRequested"
          />
        </v-col>
        <v-col
          md="6"
        >
          <p><b>Phone (optional)</b></p>
            <vue-country-code
              style="display: flex; float: left;"
              @onSelect="setCountryCode"
              :preferredCountries="['us']"
            >
            </vue-country-code>
            <v-text-field
              v-model="phoneNumber"
              name="phonenumber"
              :rules="phoneRules"
              @keypress="isNumber($event)"
              :maxlength="12"
              class="login-input"
              placeholder="XXX-XXX-XXXX"
              required
              outlined
              :readonly="confirmationCodeRequested"
            />
        </v-col>
        <!-- <v-col
          md="6"
        >
          <P>I Am A Realtor</P>
          <v-checkbox
            v-model="isRealtor"
            label=""
            class="mt-0"
          />
        </v-col> -->
      </v-row>
      <!-- <v-row v-if="isRealtor">
        <v-col>
          <v-text-field
            v-model="realtorID"
            :rules="[realtorIsRequired]"
            class="login-input"
            placeholder="Realtor ID Number"
            :type="text"
            outlined
          />
        </v-col>
      </v-row> -->
      <v-row
        v-if="screenTypeSmall"
        class="mt-0"
      >
        <v-col
          :md="screenTypeSmall ? '12' : '6'"
          class="password-col"
        >
          <p><b>Password</b></p>
          <v-tooltip
          top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                id="password"
                v-bind="attrs"
                v-on="on"
                v-model="password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="passwordRules"
                name="password"
                class="login-input"
                :type="show1 ? 'text' : 'password'"
                hint=""
                counter
                outlined
                @click:append="show1 = !show1"
                :readonly="confirmationCodeRequested"
              />
            </template>
            <span>Must contain minimum 8 characters<br>Must contain at least 1 number and 1 special character<br>Must contain at least 1 uppercase and 1 lowercase character</span>
          </v-tooltip>
        </v-col>
      </v-row>
    <v-row
      v-if="screenTypeSmall"
      class="mt-0"
    >
        <v-col
          style="margin-top:5px"
          :md="$vuetify.breakpoint.xs ? '12' : '6'"
          class="password-col"
        >
          <p><b>Re-enter Password</b></p>
          <v-text-field
            id="passwordAgain"
            v-model="passwordAgain"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            name="passwordAgain"
            class="login-input"
            :type="show2 ? 'text' : 'password'"
            counter
            outlined
            @click:append="show2 = !show2"
            :readonly="confirmationCodeRequested"
          />
        </v-col>
      </v-row>
      <v-row
        v-if="!screenTypeSmall"
        class="mt-0"
      >
        <v-col
          :md="screenTypeSmall ? '12' : '6'"
          class="password-col"
        >
          <p><b>Password</b></p>
          <v-tooltip
          top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                id="password"
                v-bind="attrs"
                v-on="on"
                v-model="password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="passwordRules"
                name="password"
                class="login-input"
                :type="show1 ? 'text' : 'password'"
                hint=""
                counter
                outlined
                @click:append="show1 = !show1"
                :readonly="confirmationCodeRequested"
              />
            </template>
            <span>Must contain minimum 8 characters<br>Must contain at least 1 number and 1 special character<br>Must contain at least 1 uppercase and 1 lowercase character</span>
          </v-tooltip>
        </v-col>
        <v-col
          :md="$vuetify.breakpoint.xs ? '12' : '6'"
          class="password-col"
        >
          <p><b>Re-enter Password</b></p>
          <v-text-field
            id="passwordAgain"
            v-model="passwordAgain"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            name="passwordAgain"
            class="login-input"
            :type="show2 ? 'text' : 'password'"
            counter
            outlined
            @click:append="show2 = !show2"
            :readonly="confirmationCodeRequested"
          />
        </v-col>
      </v-row>
      <v-row
        class="mt-0"
      >
        <v-col
          v-if="confirmationCodeRequested"
          md="6"
        >
          <p><b>Email Confirmation Code</b></p>
          <v-text-field
            v-model="confirmationCode"
            name="confirmationcode"
            class="login-input"
            required
            outlined
          />
        </v-col>
        <v-col
          v-if="confirmationCodeRequested"
          md="6"
        >
          <br>
          <v-btn
          :disabled="!valid"
          block
          color="primary"
          @click="validate && resendVerificationCode()"
          @keyup.native.enter="valid && resendVerificationCode($event)"
        >
          Resend Code
        </v-btn>
        </v-col>
      </v-row>
      <!-- <v-row
      align="center"
      justify="center"
      >
        <v-col
          md="12"
          style="max-width:440px;"
        >
          <p
            style="margin-top:2px"
          >
            <b>Promo Code</b>
            <span
            v-if = "isInvalidPromo"
            style="float: right; color: red; font-size: 15px"
            >
            Invalid promo code!
            </span>
          </p>
          <v-text-field
            v-model="promoCode"
            name="promocode"
            class="login-input"
            @blur="isPromoCodeValid"
            required
            outlined
            :readonly="confirmationCodeRequested"
            :maxlength="10"
          />
        </v-col>
      </v-row> -->
      <br
      v-if="!confirmationCodeRequested"
      >
      <p
        v-if="regError"
        class="register-error"
        style="margin-top:10px"
      >
        {{ regError }}
      </p>
      <div>
        <br>
      <!-- <p>By checking all of the following boxes you certify that you have read and agree to be bound by all of our website policies and conditions:</p> -->
      <v-row>
        <v-col
        md="12"
        >
      <input
        type="checkbox"
        v-model="agreementChecked"
        :disabled="confirmationCodeRequested"
      >
      <label
        class="check-box"
      >
        &nbsp;
        I agree to the Honely
        <a
          href="https://honely.com/privacy"
          target="_blank"
        >
          Privacy Policy
        </a>, &nbsp;
        <a
          href="https://honely.com/terms"
          target="_blank"
        >
          Terms of Usage
        </a>, &nbsp;
        <a
          href="https://honely.com/disclaimer"
          target="_blank"
        >
          Disclaimer
        </a>&nbsp;and
        <a
          href="https://honely.com/licensing"
          target="_blank"
        >
          Licensing Agreement
        </a>.
      </label>
        </v-col>
      </v-row>
      <v-row>
        <v-col
        md="12"
        >
      <input
        type="checkbox"
        v-model="emailConsentChecked"
        :disabled="confirmationCodeRequested"
      >
      <label
        class="check-box"
      >
        &nbsp;
        I consent to receive Honely newsletters and promotional content via email. For further information, please consult the
        <a
          href="https://honely.com/privacy"
          target="_blank"
        >
          Privacy Policy
        </a>.
      </label>
        </v-col>
      </v-row>
      </div>
      <v-card-actions
        v-if="!confirmationCodeRequested"
        class="px-0 mt-2"
      >
        <v-spacer />

        <v-btn
          :disabled="!valid || !agreementChecked || !(userName && firstName && lastName && email && password && passwordAgain)"
          block
          color="primary"
          class="register-button"
          @click="validate && signUp()"
          @keyup.native.enter="valid && signUp($event)"
        >
          Register
        </v-btn>
      </v-card-actions>
      <v-card-actions
        v-if="confirmationCodeRequested"
        class="px-0 mt-2"
      >
        <v-spacer />

        <v-btn
          v-if="!showLoading"
          :disabled="!(confirmationCode)"
          block
          color="primary"
          class="register-button"
          @click="validate && confirmSignUpAndLogIn()"
          @keyup.native.enter="valid && confirmSignUpAndLogIn($event)"
        >
          Confirm Sign Up
        </v-btn>
        <div
        id="signupLoadingSpinner"
        v-if="showLoading"
        >
          <circle-spin :loading='true'></circle-spin>
        </div>
      </v-card-actions>
    </v-form>
    <div />
  </div>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/vue/2.5.17/vue.js"></script>
<script>
/* eslint-disable */
  import axios from 'axios'
  import { authMethods } from '@/store/helpers'
  import { mapState, mapGetters } from 'vuex'
  import { bus } from '@/main'
  import Amplify, { Auth } from 'aws-amplify'
  import awsExports from '../../aws-config'
  import Vue from "vue";
  import VueCountryCode from "vue-country-code";
  Vue.use(VueCountryCode);
  import config from '../../aws-exports'
  Amplify.configure(config)
  // Amplify.configure(awsExports)
  async function signOut() {
    try {
        await Auth.signOut();
        // console.log('vx: user signed out')
    } catch (error) {
        // console.log('vx: error signing out: ', error);
    }
}
// signOut()
  var thatOneReturns=null
  async function isUserLoggedIn() {
    try {
        thatOneReturns=await Auth.currentAuthenticatedUser();
        // console.log('vx: from the form thatOneReturns:', thatOneReturns)
        // console.log("vx: from the form user is logged in");
    } catch {
        // console.log('vx: from the form thatOneReturns:', thatOneReturns)
        // console.log("vx: from the form user is logged out");
    }
}
// isUserLoggedIn()
  export default {
    data: () => {
      return {
        isInvalidPromo: false,
        promoCode: '',
        emailConsentChecked: true,
        showLoading: false,
        agreementChecked: false,
        privacyPolicyChecked: false,
        termsChecked: false,
        disclaimerChecked: false,
        licensingChecked: false,
        lazy: true,
        screenTypeSmall: false,
        show1: false,
        show2: false,
        valid: false,
        countryCode: '+1',
        userName: '',
        firstName: '',
        checkUserNameExists: false,
        userNameRules: [
          v => !!v || 'Username is required',
          // v => /^[A-Za-z][A-Za-z0-9]*$/.test(v) || 'Username cannot contain spaces',
          v => /^[A-Za-z0-9]*$/.test(v) || 'User name cannot contain spaces or special characters.',
          v => v.length >= 5 || 'Username has to be at least 5 characters',
          v => v.length <= 15 || 'Username cannot be more than 15 characters',
        ],
        firstNameRules: [
          v => !!v || 'First name is required',
          v => /^[A-Za-z][A-Za-z\s]*$/.test(v) || 'Name cannot contain numbers or special characters.',
        ],
        lastName: '',
        lastNameRules: [
          v => !!v || 'Last name is required',
          v => /^[A-Za-z][A-Za-z\s]*$/.test(v) || 'Name cannot contain numbers or special characters.',
        ],
        phoneNumber: '', // vx: newly added.. Need to handle in other places..
        phoneRules: [
          // v => v.length >= 10 || 'Atleast 10 characters',
          v => /^$|^((?!(0))(?!(1))[0-9A-Za-z-]{9,12})$/.test(v) || 'Invalid Phone Number',
          v => /^$|\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(v) || 'Invalid Phone Number',
          // v => /^$^|[+1]$|^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{5,7}$/.test(v) || 'Phone Number must be valid',
        ],
        email: '',
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        password: '',
        passwordAgain: '',
        passwordRules: [
           v => !!v || 'Required.',
           v => v.length >= 8 || 'Min 8 characters',
          //  v => !/^[A-Za-z0-9]*$/.test(v) || 'Password should contain atleast 1 special characters',
          //  v => !/[0-9~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/.test(v) || 'Password should contain atleast 1 letter',
          //  v => !/[A-Za-z~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/.test(v) || 'Password should contain atleast 1 number',
          //  v => !/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/.test(v) || 'Password should contain special characters',
        ],
        confirmationCode: null,
        isRealtor: false,
        realtorID: '',
        regError: null,
        confirmationCodeRequested: false,
      }
    },
    computed: {
      ...mapState('auth', ['currentUser']),
      ...mapGetters('auth', ['cognitoSignUpReturns']),
      checkUserNameRule () {
        // v => !!v || 'Username is required',
        //   // v => /^[A-Za-z][A-Za-z0-9]*$/.test(v) || 'Username cannot contain spaces',
        //   v => /^[A-Za-z0-9]*$/.test(v) || 'User name cannot contain spaces or special characters.',
        //   v => v.length >= 5 || 'Username has to be atleast 5 characters',
        //   v => v.length <= 15 || 'Username cannot be more than 15 characters',
        var user_name_to_check = this.userName
        // console.log('vx: user_name_to_check.length', user_name_to_check.length)
        if (this.checkUserNameExists === true) {
          return 'Username exists. Please use a different username.'
        }
        if (user_name_to_check.length >15) {
          return 'Username cannot be more than 15 characters'
        }
        if (user_name_to_check.length <5) {
          return 'Username has to be at least 5 characters'
        }
        if (!/^[A-Za-z0-9]*$/.test(user_name_to_check)) {
          return 'User name cannot contain spaces or special characters.'
        }
      },
      matchRule () {
        return v => (!!v && v) === this.password || 'The passwords you entered don\'t match'
      },
      matchRule2 () {
        return v => (!!v && v) === this.passwordAgain || 'The passwords you entered don\'t match'
      },
      realtorIsRequired () {
        return v => (!!this.isRealtor && !!v) || 'Realtor ID Required'
      },
    },
    mounted: function () {
      var x = document.getElementsByClassName("v-icon")
      for(var i=0;i<x.length;i++){
        // console.log('vx: x[i][&lsquo;classList&rsquo;][4]', x[i]['classList'][4])
        if (x[i]['classList'][4]==='mdi-eye-off') {
          x[i].style.marginBottom="14px"
        }
      }
    },
    methods: {
      ...authMethods,
      isPromoCodeValid: function () {
        if (this.promoCode.length > 0) {
          axios.get('https://api.honely.com/lookup/promo_code_check', {
            params: {
              promo_code: this.promoCode,
            },
          })
          .then((response)=>{
            if(!response.data.check){
              this.isInvalidPromo = true
              setTimeout(() => {
                this.isInvalidPromo = false
              }, 2000)
            }
          })
        }
      },
      isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 45) {
          evt.preventDefault();;
        } else {
          return true;
        }
      },
      checkUserName: function () {
        var user_name_to_check = this.userName
        // console.log('vx: usernmae',this.userName)
        Auth.signIn(user_name_to_check.toLowerCase(), '123').then(res => {
          return 'Username already exists';
      }).catch(error => {
        const code = error.code;
        console.log(error);
        switch (code) {
            case 'UserNotFoundException':
              // console.log("vx: UserNotFoundException")
              this.checkUserNameExists = true
              return true
              //  return !this.redirectToRegister(email);
            case 'UserLambdaValidationException':
              console.log("UserLambdaValidationException")
              this.checkUserNameExists = false
              return true
            case 'NotAuthorizedException':
              // console.log("vx: NotAuthorizedException")
              this.checkUserNameExists = true
              return true
            case 'PasswordResetRequiredException':
              // console.log("vx: PasswordResetRequiredException")
              this.checkUserNameExists = true
              return true
            case 'UserNotConfirmedException':
              // console.log("vx: UserNotConfirmedException")
              this.checkUserNameExists = true
              return true
            default:
              this.checkUserNameExists = false
              return false
        }
      })
      },
      watch: {
      phoneNumber: function(newValue) {
        const result = newValue.replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        Vue.nextTick(() => this.phoneNumber = result);
      }
      },
      acceptNumber() {
        var x = this.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        this.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
      },
      setPrivacyChecked () {
        this.privacyPolicyChecked = true
      },
      setTermsChecked () {
        this.termsChecked = true
      },
      setDisclaimerChecked () {
        this.disclaimerChecked = true
      },
      setLicensingChecked () {
        this.licensingChecked = true
      },
      setCountryCode({name, iso2, dialCode}) {
       if (this.$vuetify.breakpoint.name === 'xs'){
         this.screenTypeSmall = true
       }
     },
      validate () {
        this.$refs.form.validate()
      },
      async resendVerificationCode () {
        axios.get('https://api.honely.com/lookup/email_verification_service?email='+this.email)
        .then(async () => {
          this.regError='Too much time has elapsed. Please sign up again.'
          // this.regError='Too much time has elapsed. Please click register again.'
          // this.confirmationCode = null
          // this.confirmationCodeRequested = false
        }).catch(async ()=>{
          await this.$store.dispatch('auth/cognitoResendConfirmationCode',{username : this.userName})
          this.regError='Resent Email Verification Code!'
          this.confirmationCodeRequested=true
        })
      },
      signUp () {
        // var username = this.firstName
        // var password = this.password
        // var email = this.email
        // var phone_number = this.phoneNumber
        if (!this.userName || !this.firstName || !this.lastName || !this.email || !this.password || !this.countryCode) {
          this.regError='Please fill out the missing fields'
        } else {
        var fullPhone = ""
        if (this.phoneNumber == ""){
          fullPhone = ""
        }
        else{
          fullPhone = this.countryCode + this.phoneNumber
          fullPhone = fullPhone.replace(/-/g, "");
        }
        var emailConsent = this.emailConsentChecked?'TRUE':'FALSE'
        const params = {
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          membership_type: 'FREE',
          phone_number: fullPhone,
          user_name: this.userName,
          email_consent: emailConsent,
        }
        if(this.passwordAgain != this.password){
          this.regError='Passwords must match.'
        }
        else{
        axios.get('https://api.honely.com/lookup/email_verification_service?email='+this.email)
        .then(async ()=>{
          try{
        await this.$store.dispatch('auth/cognitoSignUp',{username : this.userName, password: this.password, email: this.email, phone_number: fullPhone, first_name: this.firstName, last_name: this.lastName})
        this.regError='Email Confirmation Code sent to your inbox! (If you haven\'t received it, please check your spam folder.)'
        this.confirmationCodeRequested=true
        axios.post('https://api.honely.com/lookup/unconfirmed_user_addition', params)
        } catch (error) {
          if (error.message=='User already exists') {
            this.regError='Username already exists. Please use a different username or sign in to existing account'
          } else {
            this.regError=error.message
          }
          // console.log('vx: error signing up:', error);
        }
        }).catch((error)=>{
          // console.log('vx: error signing up:', error);
          if(this.email==''){
            this.regError='Please enter a valid email.'
          } else {
            this.regError='This email is already in use. Please use a different email address or sign in to existing account'
          }
        })
        }
      }
        // const { user } = Auth.signUp({
        //   username,
        //   password,
        //   attributes: {
        //     email,
        //     phone_number,
        //   },
        // })
        //   .then(() => {
        //     this.confirmationCodeRequested = true
        //     this.regError=null
        //     })
        //   .catch(error => {
        //     this.regError=error
        //     console.log(error)
        //     })
      },
      async confirmSignUpAndLogIn() {
        var fullPhone = ""
        if (this.phoneNumber == ""){
          fullPhone = ""
        }
        else{
          fullPhone = this.countryCode + this.phoneNumber
          fullPhone = fullPhone.replace(/-/g, "")
        }
        var emailConsent = this.emailConsentChecked?'TRUE':'FALSE'
        const params = {
          user_name: this.userName,
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          phone_number: fullPhone,
          membership_type: 'FREE',
          email_consent: emailConsent,
          promo_code: this.promoCode,
        }
        try {
          this.showLoading = true
          await this.confirmSignUp()
          await axios.delete('https://api.honely.com/lookup/unconfirmed_user_deletion', {data: {email: this.email}})
          await this.signIn()
          this.$emit('successfulLogin')
          bus.$emit('successfulLogin')
          await axios.post('https://api.honely.com/lookup/register_service', params)
          if (this.$route.path) {
              // if is one of the following paths, reload the page
              if (this.$route.path.startsWith('/forecast') || this.$route.path === '/subscribe') {
                // console.log('vx: this.$route.path', this.$route.path)
                // location.reload()
                this.$router.go()
              }
              /* if (this.$route.path.startsWith('/listing')) {
                this.$router.push({ name: 'Home'})
              }*/
              if (this.$route.path.startsWith('/listing')) {
                this.$router.replace(this.$route.path).catch(()=>{})
              }
            }
        } catch (error) {
          // console.log('vx: Error while confirming and signing in', error)
          this.regError=error.message
          this.showLoading = false
        }
        //   await this.confirmSignUp()
        // .then(async ()=>{
        //   await this.signIn()
        //   .then(()=>{
        //     this.$emit('successfulLogin')
        //     bus.$emit('successfulLogin')
        //     axios.post('https://api.honely.com/lookup/register_service', params)
        //     })}).catch((error)=>{
        //       console.log('Error while confirming and signing in', error)
        //       this.regError=error
        //       })
        // await this.$store.dispatch('auth/cognitoConfirmSignUp',{username : this.firstName, code: this.confirmationCode})
        // await this.$store.dispatch('auth/cognitoSignIn',{username : this.firstName, password: this.password})
    // try {
    //   var username=this.firstName
    //   var code=this.confirmationCode
    //   await Auth.confirmSignUp(username, code);
    //   this.regError=null
    //   await this.signIn()
    //   this.$emit('successfulLogin')
    //   bus.$emit('successfulLogin')
    //   console.log('user confirmed')
    // } catch (error) {
    //     this.regError=error
    //     console.log('error confirming sign up', error);
    // }
},
async confirmSignUp() {
  // try {
    await this.$store.dispatch('auth/cognitoConfirmSignUp',{username : this.userName, code: this.confirmationCode})
    this.regError=null
    this.confirmationCodeRequested=true
  // } catch (error) {
  //   this.regError=error
  //   console.log('error confirming sign up', error);
  // }
},
async userExist(username) {
  // console.log("vx: UserExists check for: ",username)
  return Auth.signIn(username.toLowerCase(), '123').then(res => {
    return false;
  }).catch(error => {
    const code = error.code;
    // console.log('vx: in userExists- error', error);
    switch (code) {
        // case 'UserNotFoundException':
        //    return !this.redirectToRegister(email);
        case 'NotAuthorizedException':
          return true
        case 'PasswordResetRequiredException':
          return true
        case 'UserNotConfirmedException':
          return true
        default:
          return false;
    }
  });
},
async signIn() {
  try{
    await this.$store.dispatch('auth/cognitoSignIn',{username : this.userName, password: this.password})
  } catch (error) {
    this.regError=error
    // console.log('vx: error signing in', error)
  }
    // try {
    //     var username=this.firstName
    //     var password=this.password
    //     const user = await Auth.signIn(username, password);
    //     console.log(user)
    // } catch (error) {
    //     console.log('error signing in', error);
    // }
},
// async isUserLoggedIn() {
//     try {
//         await Auth.currentAuthenticatedUser();
//         console.log("user is logged in");
//     } catch {
//         console.log("user is logged out");
//     }
// },
      submit () {
        var fullPhone = ""
        if (this.phoneNumber == ""){
          fullPhone = ""
        }
        else{
          fullPhone = this.countryCode + this.phoneNumber
          fullPhone = fullPhone.replace(/-/g, "");
        }
        const params = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          password: this.password,
          phoneNumber: fullPhone,
        }
        // console.log('vx: submit')
        axios.post('/auth/api/v1/register', params)
          .then(() => {
            // console.log('vx: registered')
            this.logIn({
              username: this.email,
              password: this.password,
            })
              .then(() => {
                this.regError = null
                // console.log('vx: loggedIn ' + this.isRealtor)
                if (this.isRealtor) {
                  this.setIsRealtor()
                } else {
                  // TODO: if this is not in an overlay, need to go to next router route
                  // Should set a prop boolean of isInOverlay or similar
                  // this.$router.push({ name: 'Home' })
                  this.$emit('successfulLogin')
                  bus.$emit('successfulLogin')
                }
              })
              .catch((error) => {
                // console.log('vx: error')
                // console.log(error)
                this.tryingToLogIn = false
                // todo catch 401 not authenticated
                this.regError = error
              })
          })
          .catch(err => {
            // console.log(err)
            if (err.response.status === 409) {
              this.regError = 'This email address has already been registered.'
            } else {
              this.regError = err.response.data
            }
          })
      },
      setIsRealtor () {
        axios.post('/api/v1/set-realtor',
                   {
                     realtorId: this.realtorID,
                   },
                   {
                     headers: {
                       Authorization: 'Bearer ' + this.currentUser,
                     },
                   })
          .then(() => {
            this.$emit('successfulLogin')
          })
          .catch(err => {
            // console.log(err)
          })
      },
    },
  }
</script>

<style lang="sass" scoped>
.v-text-field--outlined > .v-input__control > .v-input__slot
  height: 30px
.v-text-field--enclosed .v-input__append-inner
  margin-top: 0px
.register-button
  margin-top: 5px !important
  font-size: 17px !important
  font-family: $heading-font-family
  letter-spacing: .05em
.register-error
  color: $color-orange
#create-account
  .row
      .col
        padding: 5px 12px !important
  .password-col
    .v-text-field--enclosed.v-text-field--outlined
      .v-input__prepend-inner
        border: 1px solid green
        margin-top: 5px !important
</style>
<style scoped>
.check-box{
  margin-right:30px;
}
</style>
<style scoped>
#signupLoadingSpinner {
  min-height: 40px;
}
#signupLoadingSpinner >>> .sk-fading-circle {
  margin: 0px auto;
  position: absolute;
  left: 50%;
}
</style>

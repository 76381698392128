<template>
  <v-btn
    :color="color"
    :depressed="depressed"
    :min-width="minWidth"
    :tile="tile"
    class="font-weight-bold"
    x-large
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </v-btn>
</template>

<script>
  export default {
    name: 'BaseBtn',

    props: {
      color: {
        type: String,
        default: 'primary',
      },
      depressed: {
        type: Boolean,
        default: true,
      },
      minWidth: {
        type: [Number, String],
        default: 164,
      },
      tile: {
        type: Boolean,
        default: true,
      },
    },
  }
</script>

<style lang="sass">
  .v-btn.btn-green
    background-color: $color-emerald
    border:1px solid white
    color: white
    border-radius: 10px

  .v-btn, .v-btn__content
    text-decoration: none !important
    line-height: inherit !important
</style>

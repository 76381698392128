<template>
  <v-container>
    <v-row
      justify="start"
      class="border-me-not"
    >
      <v-col
        cols="12"
      >
        <!-- <v-card>
          <span class="error--text">{{ regError }}</span>
          <v-card-title>User Information</v-card-title>
          <v-card-text>
            <v-form
              ref="form"
              v-model="valid"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="user.first_name"
                    label="First Name"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="user.last_name"
                    label="Last Name"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="user.cell_phone"
                    label="Cell Phone"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="user.work_phone"
                    label="Work Phone"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="user.address_1"
                    label="Address 1"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="user.address_2"
                    label="Address 2"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="user.city"
                    label="City"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="user.state"
                    label="State"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="user.zip"
                    label="Zip"
                  />
                </v-col>
              </v-row>

              <div class="d-flex">
                <v-spacer />
                <v-btn
                  color="primary"
                  :disabled="!valid"
                  @click="submitInformationUpdate()"
                >
                  Save
                </v-btn>
              </div>
            </v-form>
          </v-card-text>
        </v-card> -->
        <v-card>
          <v-card-title>Account Preferences</v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                cols="6"
                md="6"
              >
              <v-img
                    lazy-src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png"
                    max-height="250"
                    max-width="150"
                    src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png"
                    ></v-img>
              <v-btn
                style="margin-top:5px"
                :color="passwordformOpen ? 'secondary' : 'primary'"
                @click="openChangeForm"
              >
                Change Password
                <i
                  style="margin-left:5px"
                  :class="passwordformOpen ? 'fas fa-arrow-up' : 'fas fa-arrow-right'"
                >
                </i>
              </v-btn>
              <v-form
              v-if="passwordformOpen"
              ref="form"
              v-model="valid"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-text-field
                    style="margin-top:5px"
                    v-model="oldPassword"
                    label="Old Password"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show2 ? 'text' : 'password'"
                    name="oldpassword"
                    counter
                    outlined
                    @click:append="show2 = !show2"
                  />
                  <v-text-field
                    v-model="newPassword"
                    label="New Password"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    :rules="passwordRules"
                    counter
                    outlined
                    @click:append="show1 = !show1"
                    name="newpassword"
                  />
                  <v-btn
                    :disabled="!valid"
                    block
                    color="primary"
                    class="register-button"
                    @click="validate && changePassword()"
                  >
                    Submit
                  </v-btn>
                </v-col>
              </v-row>
              </v-form>
              <p
                v-if="changeError"
                class="register-error"
                style="margin-top:10px"
              >
                {{ changeError }}
              </p>
              </v-col>
              <!-- <v-col
                cols="6"
                md="6"
              >
              <p><b>Email:</b><span style="color:green"> {{email}}</span></p>
              <p><b>Subscription:</b> {{startDate}}</p>
              <p><b></b>  {{renews}}</p>
              <v-btn
                v-if="!isHiddenSubscription"
                color="primary"
                @click="unsubscribe_stripe()"
              >
                Unsubscribe
              </v-btn>
              </v-col> -->
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
              <v-switch
              v-model="email_consent"
              @change="togglePromotionalConsentStatus"
              :loading="promotionalConsentLoading"
              >
                <template v-slot:label>
                  Get Honely newsletters and promotional content via email
                </template>
              </v-switch>
              <span
              v-if="toggleError"
              style="color: red"
              >Error in changing your preference. Please refresh the page and try again.</span>
              </v-col>
            </v-row>

            <!-- <div class="d-flex">
              <v-spacer />
              <v-btn
                color="primary"
                @click="submitPasswordChange()"
              >
                Save
              </v-btn>
            </div> -->
          </v-card-text>
        </v-card>
        <!-- <v-card
          style="margin-top:15px; margin-bottom:15px"
        >
          <v-card-title
          class="refer-a-friend-title"
          >
            <b>Refer A Friend!</b>
          </v-card-title>
          <v-row
            style="margin-left:5px"
          >
            <v-col
              cols="8"
              md="8"
            >
              <h1
                style="color: green; font-size: 20px; margin-top:15px; margin-bottom:10px; font-family: 'Avalon Medium', arial;"
                class=""
              >
                <span style="color: black">Share your love for</span><span style="color: #06A550"> Honely </span><span style="color: black">and get </span><span style="color:#06A550;"> Smart Searches </span><span style="color: black">for you and your friends!</span><br>
                <span style="color: black">Get started by entering your friend's Email ID</span>
              </h1>
              <v-form
              ref="form"
              v-model="valid"
            >
              <v-row>
                <v-col
                  cols="6"
                  md="6"
                >
                  <v-text-field
                    v-model="referral_user"
                    label="Referral Email ID"
                    :rules="emailRules"
                    name="emailrules"
                  />
                </v-col>
                <v-col
                  cols="2"
                  md="2"
                  style="margin-top:5px"
                >
                  <v-btn
                color="primary"
                @click="refer()"
              >
                Refer A Friend
              </v-btn>
                </v-col>
              </v-row>
              <p
                v-if="refError"
                class="register-error"
                style="margin-top:10px"
              >
                {{ refError }}
              </p>
              </v-form>
            </v-col>
          </v-row>
        </v-card> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  /* eslint-disable */
  import Amplify, { Auth } from 'aws-amplify'
  import awsExports from '../../aws-config'
  import config from '../../aws-exports'
  Amplify.configure(config)
  // Amplify.configure(awsExports)
  import axios from 'axios'
  import {
    mapGetters, mapState,
  } from 'vuex'

  export default {
    data: () => ({
      email_consent: false,
      promotionalConsentLoading: false,
      toggleError: false,
      user: {},
      userFirstName: '',
      userLastName: '',
      currentPassword: '',
      oldPassword: '',
      newPassword: '',
      referral_user: '',
      referring_user: '',
      passwordformOpen: false,
      show1: false,
      show2: false,
      passwordRules: [
           v => !!v || 'Required.',
           v => v.length >= 8 || 'Min 8 characters',
          //  v => !/^[A-Za-z0-9]*$/.test(v) || 'Password should contain atleast 1 special characters',
          //  v => !/[0-9~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/.test(v) || 'Password should contain atleast 1 letter',
          //  v => !/[A-Za-z~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/.test(v) || 'Password should contain atleast 1 number',
          //  v => !/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/.test(v) || 'Password should contain special characters',
        ],
      emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
      email: '',
      startDate: 'No Subscription Found',
      endDate: 'No Subscription Found',
      newPassword: '',
      newPasswordConfirmation: '',
      valid: false,
      renews: "",
      isHiddenSubscription: true,
      subscriptionData: {},
      referralLink: null,
      regError: '',
      refError: '',
      changeError: '',
      firstNameRules: [
        v => !!v || 'First name is required',
      ],
      lastNameRules: [
        v => !!v || 'Last name is required',
      ],
    }),
    computed: {
    //   ...mapState('auth', ['currentUser']),
    ...mapGetters('auth', ['loggedIn', 'username', 'vxAuth', 'vxAuthDependent', 'isCognitoUserLoggedIn', 'cognitoUser']),
    //   ...mapGetters('auth', ['loggedIn', 'username', 'firstName', 'lastName']),
    },

    created () {
    //   this.load()
      this.fetchSubscription()
      this.email = this.cognitoUser.attributes.email
      this.fetchPromotionalConsentStatus()
    },
    methods: {
      fetchPromotionalConsentStatus () {
        axios.get('https://api.honely.com/lookup/user_profile',
          {
              params: {
                email: this.cognitoUser.attributes.email,
              },
          })
          .then((response) => {
            this.email_consent = response.data.email_consent === 'True' ? true : false
          })
      },
      togglePromotionalConsentStatus () {
        this.promotionalConsentLoading = true
        var consent= this.email_consent?'TRUE':'FALSE'
        var params = {
          email: this.cognitoUser.attributes.email,
          consent: consent,
        }
        axios.post('https://api.honely.com/lookup/email_consent', params)
        .then(() => {
          this.promotionalConsentLoading = false
        })
        .catch(()=>{
          this.toggleError = true
        })
      },
      openChangeForm (){
        // console.log('this.passwordformOpen',this.passwordformOpen)
        if (this.passwordformOpen === true){
          this.passwordformOpen = false
        }
        else{
        this.passwordformOpen = true
        }
      },
      changePassword (){
        // console.log('oldPassword',this.oldPassword)
        // console.log('newPassword',this.newPassword)
        Auth.currentAuthenticatedUser()
        .then(user => {
            return Auth.changePassword(user, this.oldPassword, this.newPassword);
        })
        .then(data => 
        // console.log(data),
        this.changeError = "Password Changed Succesfully")
        .catch(err => {
        if (err.code == "LimitExceededException"){
          this.changeError = "Password Limit Change Exceeded. Please try again later.";
        }
        else if (err.code == "NotAuthorizedException"){
          this.changeError = "Incorrect Current password provided";
        }
        else if (err.code == "InvalidPasswordException"){
          this.changeError = err.message;
        }
        else{
          this.changeError = "Password Change Failed. Please check your network connection and try again";
        }
        })
      },
      fetchSubscription (){
            // console.log('email',this.cognitoUser.attributes.email)
            axios.get('https://api.honely.com/lookup/fetch-subscription',
                  {
                      params: {
                      email: this.cognitoUser.attributes.email,
                    },
                    // headers: {
                    //   Authorization: 'Bearer ' + this.currentUser,
                    // },
                  })
                   .then((response) => {
            // console.log(response.data)
            // console.log(response.data.referral_link)
            this.referralLink = response.data.referral_link
            this.referring_user = response.data.referral_link_name
            // console.log(response.data.data.end_date)
            // console.log(response.data.data.start_date)
            this.subscriptionData = response.data
            this.startDate = "No Subscription Found"
            this.renews = "No Subscription Found"
            if (response.data == null) {
                this.renews = "No Subscription Found"
            }
            else{
                this.startDate = "Started on " + response.data.data.start_date
                if (response.data.data.renews ==1 ){
                this.isHiddenSubscription = false
                this.renews = "Renews on " + response.data.data.end_date
                }
                else{
                    this.startDate = "Started on " + response.data.data.start_date
                    this.renews = "Expires on " + response.data.data.end_date
                }
            }
            
          })
      },
      refer () {
        // console.log(this.referralLink)
        // console.log(this.referring_user)
        // console.log(this.email)
        const referralLink = 'https://honely.com/subscribe?code='+this.referralLink + "&name=" + this.referring_user
        const emailTo = this.referral_user
        const emailFrom = this.email

        const params = {
        emailTo: emailTo,
        referralLink: referralLink,
        referring_user: this.referring_user,
        emailFrom: emailFrom,
        subject: "Honely Referral Link"
      }
      console.log('emailTo', emailTo)
      if (emailTo == "" || emailTo == null){
        this.refError = "Email address cannot be empty"
      }
      else{
        console.log('sending email')
        axios.post('https://api.honely.com/lookup/refer_email', params)
          .then((response) => {
              // console.log('email sent', response)
              this.refError = "Email sent to " + emailTo
              // commit('ADD_FAVORITE', obj) //add in state; kyle adding only address, fips and apn
          })
          .catch((error) => {
              console.warn(error)
              this.refError = "Email sending failed. Please check your network connedtion and try again."
              return null
          })
      }

      },
      unsubscribe_stripe () {
        this.$confirm(
          'Are you sure? This cannot be undone.'
        ).then(() => {
          // user click OK
          console.log('[INFO] Unsubscribe comfirmed')
          // console.log(this.subscriptionData.data.subscription_id)
          axios.get('https://api.honely.com/lookup/cancel-subscription',
          {
              params: {
                id: this.subscriptionData.data.subscription_id,
              },
              // headers: {
              //    Authorization: 'Bearer ' + this.currentUser,
              // },
          })
          .then(() => {
            console.log("Unsubscribe")
            location.reload()
          })
          .catch(err => {
            console.log(err)
            this.regError = err.response.data
          })
        }).catch(() => {
          // user click cancel
          console.log('[INFO] Unsubscribe canceled')
        })
      },
      validate () {
        this.$refs.form.validate()
      },
    },
  }
  
</script>
<style lang="sass" scoped>
.register-error
  color: $color-orange
</style>

<style>
.swal2-container {
  font-family: "lato", "verdana", sans-serif;
}
.refer-a-friend-title {
  color: #06A550;
  font-size: 30px;
  font-family: 'Avalon Medium', arial;
}
</style>
/* eslint-disable */
// Imports
import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
// import storageHelper from 'storage-helper'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      component: () => import('@/layouts/home/Index.vue'),
      children: [
        {
          path: '',
          name: 'Home',
          component: () => import('@/views/home/Index.vue'),
          // meta: { requiresAuth: true },
        },
        // {
        //   path: 'protected',
        //   name: 'Protected',
        //   component: () => import('@/views/Protected.vue'),
        //   // meta: { authRequired: true },
        // },
        {
          path: 'admin/confirm-realtor/:uuid',
          name: 'Confirm Relator',
          component: () => import('@/views/admin/ConfirmRealtor.vue'),
          meta: { authRequired: true },
        },
        {
          path: 'about',
          name: 'About Us',
          component: () => import('@/views/about/Index.vue'),
          meta: {
            src: require('@/assets/banner-about.png'),
            copy: 'About us copy'
            // copy: '<p>Honely is a Boca Raton, Florida-based PropTech company founded by Jonathan Alexander Shah and Maxwell Schwarz , two twenty-year-old entrepreneurs with a passion to disrupt the real estate industry, that is seeking to revolutionize the journey to home and real estate ownership for millions of people at a fair value through the use of its sophisticated AI/ML-driven forecasting and valuation algorithms.</p><p>Honely’s team consists of data scientists, machine learning experts, software engineers and real estate professionals.</p><p>This blend of diverse talents has allowed Honely to bring a unique and fresh approach to real estate data analysis and forecasting at a grand scale: delivered instantaneously to the average investors and home buyers, encompassing about 150 million homes and properties in all.</p><p>Honely, with its unique and highly scalable realtime platform , is well on its way to democratize the access to important real estate statistics and valuations to anyone who needs it.</p>',
            // requiresAuth: true
          },
        },
        {
          path: 'account',
          name: 'Account',
          component: () => import('@/views/account/Index.vue'),
          // meta: {
          //   src: require('@/assets/banner-about.png'),
          //   copy: 'Honely is a Boca Raton-based company solving the problem that has plagued home buyers and owners for years. We give you the data and forecasts to help make better real estate investment decisions.',
          //   // requiresAuth: true
          // },
        },
        {
          path: 'contact',
          name: 'Contact Us',
          component: () => import('@/views/contact-us/Index.vue'),
          meta: {
            src: require('@/assets/banner-partner.jpg'),
            // requiresAuth: true
          },
        },
        {
          path: 'disclaimer',
          name: 'Disclaimer',
          component: () => import('@/views/disclaimer/Index.vue'),
          meta: {
            src: require('@/assets/banner-disclaimer.jpg'),
            copy: '',
            // requiresAuth: true
          },
        },
        {
          path: 'faq',
          name: 'FAQ',
          component: () => import('@/views/faq/Index.vue'),
          meta: {
            src: require('@/assets/banner-faq.jpg'),
            copy: '',
            // requiresAuth: true
          },
        },
        {
          path: '/favorite-listings',
          name: 'Favorite Listings',
          component: () => import('@/views/favorite-listings/Index.vue'),
          // meta: { authRequired: true },
        },
        {
          path: 'forecast',
          name: 'Smart Search',
          component: () => import('@/views/forecast/Index.vue'),
          meta: {
            src: require('@/assets/dreamstime_m_149028302.jpg'),
            copy: 'Discover the best time to buy or sell a home.',
            // requiresAuth: true
          },
        },
        {
          path: 'forecast/:fips/:apn',
          name: 'Smart Search For Property by APN',
          component: () => import('@/views/forecast/Index.vue'),
          meta: {
            src: require('@/assets/dreamstime_m_149028302.jpg'),
            copy: '',
            // requiresAuth: true
          },
        },
        {
          // path: 'forecast/:propertyId',
          path: 'forecast/:property_id',
          name: 'Smart Search For Property',
          component: () => import('@/views/forecast/Index.vue'),
          meta: {
            src: require('@/assets/dreamstime_m_149028302.jpg'),
            copy: '',
            // requiresAuth: true
          },
        },
        {
          path: 'insights',
          name: 'Insights',
          component: () => import('@/views/insights/Index.vue'),
          meta: {
            src: require('@/assets/banner-insights.jpg'),
            copy: 'Our curated blog has all the content you need to start investing in real estate like a pro, making lives easier for buyers, sellers and everyone in between.',
            // requiresAuth: true
          },
        },
        {
          path: 'insights/:year/:month/:day/:slug',
          name: 'Insights Post',
          component: () => import('@/views/insights/Post.vue'),
          meta: {
            src: require('@/assets/banner-insights.jpg'),
            copy: '',
            // requiresAuth: true
          },
        },
        {
          // path: 'listing/:property_id',
          path: 'listing/:search_id',
          // path: 'listing',
          name: 'ListingId',
          component: () => import('@/views/listings/Listing.vue'),
          meta: {
            // src: require('@/assets/banner-insights.jpg'),
            // requiresAuth: true
          },
        },
        {
          path: 'listing/:fips/:apn',
          name: 'Listing',
          component: () => import('@/views/listings/Listing.vue'),
          meta: {
            src: require('@/assets/banner-insights.jpg'),
            // requiresAuth: true
          },
        },
        {
          path: 'listing-static/:fips/:apn',
          name: 'ListingStatic',
          component: () => import('@/views/listings/ListingStatic.vue'),
          meta: {
            // src: require('@/assets/banner-insights.jpg'),
            // requiresAuth: true
          },
        },
        {
          path: 'listings',
          name: 'Listings',
          component: () => import('@/views/listings/Index.vue'),
          meta: {
            // src: require('@/assets/banner-insights.jpg'),
            // requiresAuth: true
          },
        },
        {
          path: 'listings-static',
          name: 'ListingsStatic',
          component: () => import('@/views/listings/IndexStatic.vue'),
          meta: {
            // src: require('@/assets/banner-insights.jpg'),
            // requiresAuth: true
          },
        },
        {
          path: 'logout',
          name: 'Logout',
        },
        {
          path: '/my-listings',
          name: 'MyListings',
          component: () => import('@/views/my-listings/Index.vue'),
          meta: { authRequired: true },
        },
        {
          path: '/saved-searches',
          name: 'SavedSearches',
          component: () => import('@/views/saved-searches/Index.vue'),
          // meta: { authRequired: true },
        },
        {
          path: '/my-listings/edit/:fips/:apn',
          name: 'EditListing',
          component: () => import('@/views/my-listings/Edit.vue'),
          meta: { authRequired: true },
        },
        {
          path: 'partner',
          name: 'Partner With Us',
          component: () => import('@/views/partner/Index.vue'),
          meta: {
            src: require('@/assets/banner-partner.jpg'),
            copy: 'We partner with real estate professionals and brands to help you gain exposure and get closer to homeowners, buyers and sellers.',
            // requiresAuth: true
          },
        },
        {
          path: 'post/:year/:month/:day/:slug',
          name: 'Post',
          component: () => import('@/views/insights/Post.vue'),
          meta: {
            // src: require('@/assets/banner-insights.jpg'),
            // requiresAuth: true
          },
        },
        {
          path: 'privacy',
          name: 'Privacy Policy',
          component: () => import('@/views/privacy/Index.vue'),
          meta: {
            src: require('@/assets/banner-privacy.jpg'),
            copy: '',
            // requiresAuth: true
          },
        },
        {
          path: 'terms',
          name: 'Terms of Usage',
          component: () => import('@/views/terms/Index.vue'),
          meta: {
            src: require('@/assets/banner-privacy.jpg'),
            copy: '',
            // requiresAuth: true
          },
        },
        {
          path: 'licensing',
          name: 'Licensing Agreement',
          component: () => import('@/views/licensing/Index.vue'),
          meta: {
            src: require('@/assets/banner-privacy.jpg'),
            copy: '',
            // requiresAuth: true
          },
        },
        {
          path: 'cookiepolicy',
          name: 'Cookie Policy',
          component: () => import('@/views/cookiepolicy/Index.vue'),
          meta: {
            src: require('@/assets/banner-privacy.jpg'),
            copy: '',
            // requiresAuth: true
          },
        },
        {
          path: '/profile',
          name: 'Profile',
          component: () => import('@/views/profile/Index.vue'),
          meta: { authRequired: true },
        },
        {
          path: 'reset-password/:token',
          name: 'ResetPassword',
          component: () => import('@/views/reset-password/Index.vue'),
          meta: { 
            src: require('@/assets/banner-about.png'),
            // requiresAuth: true 
          },
        },
        {
          path: '/subscribe',
          name: 'Subscribe',
          component: () => import('@/views/subscribe/Index.vue'),
          meta: {
            src: require('@/assets/banner-subscribe.jpg'),
            copy: 'Individual and team packages for our industry leading property reports and valuation forecasts.',
          },
        },
        {
          path: '/paymentSuccess',
          name: 'Payment Success',
          component: () => import('@/views/paymentSuccess/Index.vue'),
          meta: {
            src: require('@/assets/banner-subscribe.jpg'),
            copy: 'Individual and team packages for our industry leading property reports and valuation forecasts.',
          },
        },
        {
          path: '/paymentFailed',
          name: 'Payment Failed',
          component: () => import('@/views/paymentFailed/Index.vue'),
          meta: {
            src: require('@/assets/banner-subscribe.jpg'),
            copy: 'Individual and team packages for our industry leading property reports and valuation forecasts.',
          },
        },
        {
          path: '/listingNotFound',
          name: 'ListingNotFound',
          component: () => import('@/views/listingNotFound/Index.vue'),
        },
        {
          path: '*',
          name: 'FourOhFour',
          component: () => import('@/views/404/Index.vue'),
        },
      ],
    },

  ],
})

router.beforeResolve((routeTo, routeFrom, next) => {
  if (routeTo.name === 'Logout') {
    store.dispatch('auth/logOut')
    sessionStorage.clear()
    // redirect to login page
    next({ name: 'Home' })
  } else {
    next()
  }
})

router.beforeEach((routeTo, routeFrom, next) => {
  if (routeTo.query.token) {
    const token = routeTo.query.token
    store.dispatch('auth/setToken', { token: token })

    const query = Object.assign({}, routeTo.query)
    delete query.token
    router.replace({ query })
  }

  const authRequired = routeTo.matched.some((route) => route.meta.authRequired)

  if (store.getters['auth/loggedIn']) {
    store.dispatch('auth/validate').then((validUser) => {
      // Then continue if the token still represents a valid user,
      // otherwise redirect to login.
      validUser != null ? next() : redirectToHome()
    })
  } else {
    if (authRequired) {
      redirectToHome()
    } else {
      next()
    }
  }

  function redirectToHome () {
    // Pass the original route to the login component
    router.push({ name: 'Home', query: { redirectFrom: routeTo.fullPath } })
  }
})

// router.beforeEach((to, from, next) => {
//   if (to.meta.requiresAuth) {
//     if (!storageHelper.getItem('user-password')) next('protected')
//     else next()
//   } else next()
// })

export default router

<template>
  <v-theme-provider :dark="dark">
    <div class="page-contact-info">
      <base-info-card
        title="Keep In Touch With Us"
        pace="6"
      >
        Please complete the form or reach out to us using the contact information below and one of our team members will be in touch.
      </base-info-card>

      <base-business-contact dense />
    </div>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'BaseBusinessInfo',

    props: { dark: Boolean },

    data: () => ({
      business: [
        {
          icon: 'mdi-map-marker-outline',
          title: 'Address',
          text: '150 East Palmetto Park Road, Suite 800<br>Boca Raton, Florida 33432',
        },
        {
          icon: 'mdi-cellphone',
          title: 'Phone',
          text: '+1 (561) 607-5051',
        },
        {
          icon: 'mdi-email',
          title: 'Email',
          text: 'contact@honely.com',
        },
      ],
    }),
  }
</script>

<style>
.page-contact-info .d-flex {
  align-items: center;
}
.page-contact-info .d-flex h3 {
  display: none !important;
}
.page-contact-info .d-flex .mb-3 {
  margin: 0 !important;
}
</style>

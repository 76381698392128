<template>
  <v-theme-provider light>
    <base-section
      id="interior-band-cols"
      class="white-band"
      space="40"
    >
      <v-container>
        <v-row
          class="border-me-not"
        >
          <v-col
            class="intro-text"
            cols="12"
            sm="12"
            md="12"
          >
            <v-row
              class="mt-0"
            >
              <v-col
                cols="12"
                sm="9"
                md="9"
              >
                <h1
                  class="mb-3"
                >
                  {{ item.headline }}
                </h1>
                <p>
                  <span
                    v-html="item.copy"
                  />
                </p>
              </v-col>
            </v-row>
            <v-row
              class="mt-7"
            >
              <v-col
                v-for="box in item.boxes"
                :key="box.id"
                cols="12"
                sm="4"
                md="4"
              >
                <v-card
                  class="ml-0 copy-box"
                  color="transparent"
                  max-width="360"
                  outlined
                  flat
                  light
                >
                  <v-list-item three-line>
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-h5 mb-1 box-headline"
                      >
                        {{ box.headline }}
                        <i
                          :class="'mdi header-icon ' + box.iconClass"
                          aria-hidden="true"
                        />
                      </v-list-item-title>
                      <v-list-item-subtitle
                        class="mt-1"
                        v-html="box.copy"
                      />
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row
          class="border-me-not"
        >
          <v-col
            class=""
            cols="12"
            sm="12"
            md="12"
          >
            <v-btn
              text
              elevation="2"
              href="/contact"
            >
              <span>
                Get In Touch With Our Team
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'InteriorBandCols',

    data: () => ({
      title: '',
      items: [
        {
          id: 1,
          name: 'Partner With Us',
          headline: 'We’d love to be your partner.',
          copy: 'At Honely, whether you’re a real estate broker, property manager, lender or any other industry professional we can help you reach and engage with your desired audience.',
          boxes: [
            {
              id: 1,
              headline: 'Real Estate Agents & Brokers',
              copy: 'Take advantage of Honely’s industry-leading forecasting tools to aid your clients’ decision-making ability, positioning yourself at the forefront of the market.<br /><br />By sponsoring listings on Honely, you can get prominent positioning to stay in front of buyers searching for their dream homes.  Buyers and sellers will be able to find your contact details, perfect for generating leads.',
              iconClass: 'mdi-account-tie',
            },
            {
              id: 2,
              headline: 'Brands & Service Providers',
              copy: 'Use Honely to form relationships with renters, home buyers, owners, sellers and real estate professionals. By advertising you can promote your products and services nationwide or focus on a specific geography.<br /><br />Using native placements across Honely you can stay in front of your target audiences and showcase your expertise with sponsored content. Sharpen your focus with segmented emails for your thought leadership or upcoming promotions.',
              iconClass: 'mdi-office-building-outline',
            },
            {
              id: 3,
              headline: 'Lenders',
              copy: 'Reach potential borrowers by promoting your business to home buyers and sellers. Attract new business by demonstrating your knowledge with sponsored content and follow up with qualified leads that have requested to connect with a lender to discuss their financing needs.<br /><br />Home buyers flock to Honely to ensure they’re making the right investment. Take advantage of Honely’s vast reach to target your audience with segmented emails for your thought leadership or upcoming promotions.',
              iconClass: 'mdi-cash-multiple',
            },
          ],
        },
      ],
    }),

    computed: {
      item: function () {
        return this.items.find(item => item.name === this.$route.name)
      },
    },
  }
</script>

<style lang="sass">
#interior-band-cols
  .promo-header
    font-family: $heading-font-family
    font-weight: normal
    font-size: 30px
    text-align: center

  .intro-text
    font-family: $heading-font-family

  h1
    font-weight: normal
    font-size: 33px
    padding-right: 70px

  p
    font-size: 18px
    line-height: 26px
    padding-right: 70px

  .v-list-item
    padding: 0

    .box-headline
      overflow: visible

    .v-list-item__content
      overflow: visible

    .v-list-item__subtitle
      white-space: normal
      display: block
      font-family: $body-font-family
      font-size: 18px
      line-height: 26px
      color: $color-gray-shadowlt

    .header-icon
      color: $color-gray-lt
      font-size: 60px
      position: absolute
      right: 0
      bottom: 25px

  .v-btn
    background-color: $color-squash
    color: white
    font-weight: 700
    border-radius: 10px
    font-size: 16px

.v-application
  #interior-band-cols
    .box-headline.text-h5.v-list-item__title
      font-family: $body-font-family !important
      font-size: 17px !important
      font-weight: 900
      line-height: 21px
      white-space: normal
      border-bottom: 4px solid $color-orange
      padding-bottom: 6px
      padding-right: 60px
      position: relative

</style>

<template>
  <div>
    <base-info-card
      :title="title"
      :subtitle="subtitle"
      space="4"
      color="primary"
    />

    <v-form
      v-if="!sent"
      ref="form"
      v-model="valid"
      class="pa-6"
      :lazy-validation="lazy"
    >
      <div class="fields">
      <base-text-field
        class="text-field"
        v-model="name"
        :rules="nameRules"
        label="Name"
        :maxlength="25"
      />
      <p
        class="character-count"
        style="color: grey; font-size: 15px; margin-left: 5px"
        v-text="(name.length)+'/25'"
      ></p>
      </div>
      <div class="fields">
      <base-text-field
        class="text-field"
        v-model="email"
        :rules="emailRules"
        label="Email"
        :maxlength="30"
      />
      <p
        class="character-count"
        style="color: grey; font-size: 15px; margin-left: 5px"
        v-text="(email.length)+'/30'"
      ></p>
      </div>
      <div class="fields">
      <base-text-field
        class="text-field"
        v-model="subject"
        :rules="subjectRules"
        label="Subject"
        :maxlength="75"
      >
      </base-text-field>
      <p
        class="character-count"
        style="color: grey; font-size: 15px; margin-left: 5px"
        v-text="(subject.length)+'/75'"
      ></p>
      </div>
      <div class="fields">
      <base-textarea
        v-model="message"
        :rules="messageRules"
        class="mb-6 text-field"
        label="Your Need & Description"
        :maxlength="250"
      />
      <p
        class="character-count"
        style="color: grey; font-size: 15px; margin-left: 5px"
        v-text="(message.length)+'/250'"
      ></p>
      </div>
      <base-btn
        :block="$vuetify.breakpoint.smAndDown"
        :disabled="!valid"
        color="white"
        large
        outlined
        height="40"
        width="215"
        class="btn-green"
        elevation="3"
        target="_blank"
        @click="sendForm()"
      >
        Send message
      </base-btn>
    </v-form>
    <h3 v-if="sent">
      Thank you for your message!
    </h3>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'BaseContactForm',

    // Injected from the Vuetify Themeable mixin
    inject: ['theme'],

    props: {
      subtitle: String,
      title: {
        type: String,
        default: 'MAIL US YOUR MESSAGE',
      },
      postUrl: {
        type: String,
        default: 'https://api.honely.com/lookup/contact_us',
      },
    },
    data: () => {
      return {
        lazy: true,
        valid: false,
        sent: false,
        name: '',
        email: '',
        subject: '',
        message: '',
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        nameRules: [
          v => !!v || 'Name is required',
        ],
        subjectRules: [
          v => !!v || 'Subject is required',
        ],
        messageRules: [
          v => !!v || 'Message is required',
        ],
        error: '',
      }
    },
    methods: {
      validate () {
        console.log('validate')
        this.$refs.form.validate()
      },
      sendForm () {
        this.validate()

        var me = this

        var params = {
          name: this.name,
          email: this.email,
          subject: this.subject,
          description: this.message,
        }

        setTimeout(function () {
          if (me.valid) {
            axios.post(me.postUrl, params)
              .then(() => {
                me.sent = true
              })
              .catch((err) => {
                me.error = err.message
              })
          }
        }, 500)
      },
    },
  }
</script>

<style lang='scss' scoped>
.fields{
  display: flex;
}
.text-field{
  width: 100%;
}
.character-count{
  width: 50px;
}
</style>

<template>
  <v-fade-transition mode="out-in">
    <div>
    <router-view />
    <!-- <footer> -->
    <!-- <cookie-law theme="dark-lime">
      <div
      slot="message"
      style=" font-family: 'Avalon Medium', arial;"
      >
    This website uses cookies. By continuing to use the site, you agree to the use of cookies. To learn more, please view our
    <a
    href="https://www.honely.com/privacy"
    target="_blank"
    >Privacy Policy</a>
  </div>
    </cookie-law> -->
    <!-- <cookieconsent
    text="This is my new main text"
    link-text="Click here for more info"
    button-text="Okay"
    >
    </cookieconsent> -->
    <!-- </footer> -->
    </div>
  </v-fade-transition>
</template>

<script>
  // import CookieLaw from 'vue-cookie-law'
  import cookieconsent from 'v-cookie-consent'
  export default {
    name: 'App',
    // components: { CookieLaw },
    components: { cookieconsent },
    metaInfo: {
      title: 'App',
      titleTemplate: '%s | Honely',
      htmlAttrs: { lang: 'en' },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      ],
    },

  }
</script>

<style lang="sass">
  .extra-padding
    padding-bottom: 96px !important
    padding-top: 96px !important

    @media screen and (max-width: 959px)
      padding-top: 48px !important
      padding-bottom: 48px !important
</style>
<style>
.Cookie__button {
  margin-right: 200px !important;
  font-family: 'Avalon Medium', arial;
  background: #06a550 !important;
}
a {
  color: #06a550;
}
/* a:visited {
  color: #32CD32;
} */
</style>

<template>
  <v-container>
    <v-row
      justify="start"
      class="border-me-not"
    >
      <v-col
        class="intro-text"
        cols="12"
        sm="10"
        md="8"
      >
        <h1
          class="mb-1"
        >
          Favorite Listings
        </h1>
        <p v-if="!favoriteListings.length">
          You have no favorite listings.
        </p>
      </v-col>
    </v-row>

    <v-row
      justify="start"
      class="border-me-not"
    >
      <v-col
        v-for="item in favoriteListings"
        :key="item.id"
        class="intro-text"
        cols="12"
        sm="8"
        md="4"
      >
        <property-listings-card :item="item" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  /* eslint-disable */
  import { mapGetters } from 'vuex'

  export default {
    name: 'FavoriteListings',

    components: {
      PropertyListingsCard: () => import('@/components/PropertyListingsCard'),
    },

    computed: {
      ...mapGetters('listings', ['favoriteListings']),
      ...mapGetters('auth', ['loggedIn', 'username', 'vxAuth', 'vxAuthDependent', 'isCognitoUserLoggedIn', 'cognitoUser']),
    },

    mounted () {
      if (this.$store.getters['auth/isCognitoUserLoggedIn']) {
            // get current user
            axios.get('https://api.honely.com/lookup/user_profile', {
              params: {
                email: this.$store.getters['auth/cognitoUser'].attributes.email,
              },
            }).then((response) => {
              var user = response.data
              // console.log('vx: lalala'+ this.user)
              this.$store.dispatch('listings/getFavorites', { user_id: user.user_id })
            }).catch((error) => {
              console.log('[ERROR] Failed to fetch user data')
              console.log(error.response.data.errorMessage)
            })
          }
      // if (this.$store.getters['auth/loggedIn']) {
      //   this.$store.dispatch('listings/getFavorites', { fullDetails: true })
      // }
    },
  }
</script>

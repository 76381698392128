<template>
  <v-theme-provider dark>
    <section
      id="hero-alt"
      v-bind:class="{ aboutPageSection: copy === 'About us copy' }"
    >
      <base-img
        v-bind:class="{ aboutPage: copy === 'About us copy' }"
        :height="$vuetify.breakpoint.mdAndUp ? 425 : 225"
        gradient="to bottom, rgba(0,0,0,.2), rgba(0,0,0,.8)"
        :src="src"
        color="#45516b"
        flat
        max-width="100%"
        max-height="425"
        tile
      >
        <v-row
          v-if="title"
          align="end"
          class="ma-0 fill-height"
          justify="center"
        >
          <v-col
            class="header-container"
            cols="12"
          >
            <base-heading
              :title="title"
              align="left"
              space="2"
              weight="500"
            />

            <!-- base-divider
              color="primary"
              dense
            / -->
            <v-subheader
              class="page-subheader mt-6 mb-12"
            >
              <div
                v-if="copy==='About us copy'"
                id="aboutus"
              >
                <!-- <p style="font-size: 17px">Honely is a Boca Raton, Florida-based PropTech company founded by Jonathan Alexander Shah and Maxwell Schwarz , two twenty-year-old entrepreneurs with a passion to disrupt the real estate industry, that is seeking to revolutionize the journey to home and real estate ownership for millions of people at a fair value through the use of its sophisticated AI/ML-driven forecasting and valuation algorithms.</p><p style="font-size: 17px">Honely’s team consists of data scientists, machine learning experts, software engineers and real estate professionals.</p><p style="font-size: 17px">This blend of diverse talents has allowed Honely to bring a unique and fresh approach to real estate data analysis and forecasting at a grand scale: delivered instantaneously to the average investors and home buyers, encompassing about 150 million homes and properties in all.</p><p style="font-size: 17px">Honely, with its unique and highly scalable realtime platform , is well on its way to democratize the access to important real estate statistics and valuations to anyone who needs it.</p> -->
                <p style="font-size: 17px">Honely is a Boca Raton-based company that harnesses the transformative power of AI/ML and predictive analytics to deliver real estate data analysis instantaneously to investors and home buyers alike. Our team consists of a unique blend of dedicated data scientists, machine learning experts, engineers, and real estate professionals. This blend has allowed us to bring a fresh approach to real estate data and provide solutions that were previously unavailable.</p>
              </div>
              <div
                v-else
              >
                <p>
                  {{ copy }}
                </p>
              </div>
            </v-subheader>
          </v-col>
        </v-row>
      </base-img>
    </section>
  </v-theme-provider>
</template>

<script>
  /* eslint-disable */
  export default {
    name: 'SectionHeroAlt',

    provide: {
      heading: { align: 'center' },
    },

    data: () => ({
    }),

    computed: {
      title () {
        return this.$route.name
      },
      src () {
        return this.$route.meta.src
      },
      copy () {
        return this.$route.meta.copy
      },
    },
  }
</script>

<style lang="sass">
#hero-alt
  height: 425px
  margin-top: 6px
  font-family: $heading-font-family

  .page-subheader
    padding: 0
    font-family: $body-font-family
    font-size: 20px
    color: white
    max-width: 800px
    height: auto

  .header-container
    max-width: 1185px

</style>

<style lang="css">
.aboutPageSection {
  height: 425 !important;
}
.aboutPage {
  height: auto !important;
}
@media (max-width: 1264px) {
  #hero-alt .header-container {
    max-width: 900px;
  }
}
@media (max-width: 960px) {
  #hero-alt {
    height: auto;
  }
}
@media (max-width: 600px) {
  #hero-alt .page-subheader {
    font-size: 18px;
    line-height: 25px;
    text-shadow: 1px 1px 3px #000000;
  }
}
</style>

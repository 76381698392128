<template>
  <v-theme-provider dark>
    <section id="hero-short">
      <base-img
        :height="$vuetify.breakpoint.mdAndUp ? 225 : 225"
        gradient="to bottom, rgba(0,0,0,.2), rgba(0,0,0,.8)"
        :src="src"
        color="#45516b"
        flat
        max-width="100%"
        tile
      >
        <v-row
          v-if="title"
          align="end"
          class="ma-0 fill-height pl-10"
          justify="start"
        >
          <v-col cols="12">
            <base-heading
              :title="title"
              align="left"
              space="2"
              weight="500"
              class="mb-5"
            />
          </v-col>
        </v-row>
      </base-img>
    </section>
  </v-theme-provider>
</template>

<script>

  export default {
    name: 'SectionHeroShort',

    provide: {
      heading: { align: 'center' },
    },

    data: () => ({
    }),

    computed: {
      title () {
        return this.$route.name
      },
      src () {
        return this.$route.meta.src
      },
      copy () {
        return this.$route.meta.copy
      },
    },
  }
</script>

<style lang="sass">
 #hero-short
    height: 225px
    font-family: $heading-font-family

</style>

<template>
  <div
    id="forecast-band-9"
    class="mt-0 py-6"
  >
    <v-container>
      <v-row
        justify="center"
      >
        <v-col
          sm="12"
          :class="{'align-center text-center px-2': $vuetify.breakpoint.smAndDown, 'align-center text-end px-8': $vuetify.breakpoint.mdAndUp}"
        >
          <base-heading
            class="mt-0 forecast-band-header pb-1"
            title=""
            weight="medium"
            size="text-h4"
            space="1"
          >
            <span v-if="forecast && forecast.property_forecast">
              <!-- 6. -->
              <i
                class="mdi mdi-account-wrench"
                aria-hidden="true"
              />
            </span>
            Service Providers In Your Area
          </base-heading>
        </v-col>
        <v-container>
          <v-row>
            <v-col
              class="px-8 pt-0 text-start"
            >
              <span class="provider-copy">If you are a real estate agent, lender, or general contractor join our network for free leads and brand exposure.</span>
            </v-col>
            <v-col
              class="provider-CTA text-end px-8 pt-0"
            >
              <v-dialog
                v-model="dialog"
                persistent
                max-width="600px"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    class="provider-network-btn"
                    color="#07871c"
                    elevation="2"
                    large
                    v-bind="attrs"
                    v-on="on"
                  >
                    <i
                      class="mdi mdi-account-network"
                      aria-hidden="true"
                    />
                    Apply To Be Part Of Our Network
                  </v-btn>
                </template>
                <template #default="dialog">
                  <v-card>
                    <button
                      class="btn"
                      style="position: absolute;
                  top: -15px;
                  right: -5px;
                  border: none;
                  outline: none;
                  padding: 15px 15px;
                  color: white !important;"
                      @click="toggleDialog"
                    >
                      <i
                        class="fa fa-times-thin fa-2x"
                        style="color: black;"
                      />
                    </button>
                    <v-card-title>
                      <span class="provider-modal-heading text-h5">Join Our Network</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                            <v-text-field
                              v-model="firstName"
                              :rules="firstNameRules"
                              label="First Name*"
                              required
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                            <v-text-field
                              v-model="lastName"
                              :rules="lastNameRules"
                              label="Last Name*"
                              required
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                            <v-text-field
                              v-model="email"
                              :rules="emailRules"
                              label="Email*"
                              required
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                            <v-text-field
                              v-model="phone"
                              :rules="phoneRules"
                              label="Phone*"
                              type="phone"
                              :maxlength="10"
                              required
                              @keypress="isNumber($event)"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                          >
                            <v-select
                              v-model="serviceProvided"
                              :items="['Real Estate Agent', 'Financial Lender', 'General Contractor', 'Misc']"
                              :rules="serviceRules"
                              label="Service provided*"
                              required
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                      <small>*indicates required field</small>
                    </v-card-text>
                    <span
                      v-if="isFailure"
                      style="float:left; margin-left:30px; color:red; "
                    >
                      Error in adding your information. Please try again.
                    </span>
                    <span
                      v-if="isSuccess"
                      style="float:left; margin-left:30px; color: #06A550"
                    >Information added successfully!
                    </span>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        color="blue darken-1"
                        text
                        :disabled="!(firstName && lastName && email && phone && serviceProvided)"
                        @click="submitAgent"
                      >
                        Join
                      </v-btn>
                      <!-- <v-btn
                        color="blue darken-1"
                        text
                        @click="toggleDialog"
                      >
                        Sign up
                      </v-btn> -->
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </v-col>
          </v-row>
        </v-container>
      </v-row>
      <!-- <v-row class="justify-center">
        <v-col
          class="d-flex justify-space-between align-center"
          cols="11"
        >
          <h2 class="provider-heading">
            <i
              class="mdi mdi-sign-real-estate"
              aria-hidden="true"
            />
            Real Estate Agents
          </h2>
        </v-col>
      </v-row> -->
      <!-- <v-container
        class="d-flex flex-wrap justify-center my-3"
      >
        <v-row class="d-flex flex-wrap pa-2 justify-center">
          <v-col
            v-for="n in 3"
            :key="n"
            class="d-flex flex-wrap pa-2 justify-center"
            cols="12"
            lg="4"
            md="6"
            sm="12"
          >
            <v-hover>
              <template #default="{ hover }">
                <v-card
                  class="pa-2"
                  :elevation="hover ? 24 : 6"
                  min-height="275px"
                  max-width="385px"
                  color="white"
                >
                  <v-row
                    class="d-flex flex-wrap"
                    no-gutters
                  >
                    <v-col
                      class="pr-5"
                      cols="5"
                    >
                      <v-avatar
                        class="agent-photo mx-auto"
                        rounded
                        color="grey"
                        size="150"
                      >
                        <v-img src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg" />
                      </v-avatar>
                      <v-card-title>
                        Agent name
                      </v-card-title>
                      <v-card-subtitle
                        class="pr-0"
                      >
                        Agent company
                      </v-card-subtitle>
                    </v-col>
                    <v-col
                      cols="7"
                      class="agent-details pl-2"
                    >
                      <v-card-text class="agent-detail-copy pa-1">
                        <i
                          class="mdi mdi-deskphone pr-1"
                          aria-hidden="true"
                        /> +1 999-999-9999
                      </v-card-text>
                      <v-card-text class="agent-detail-copy pa-1">
                        <i
                          class="mdi mdi-cellphone pr-1"
                          aria-hidden="true"
                        /> +1 999-999-9999
                      </v-card-text>
                      <v-card-text class="agent-detail-copy pa-1">
                        <i
                          class="mdi mdi-phone pr-1"
                          aria-hidden="true"
                        /> +1 999-999-9999
                      </v-card-text>
                      <v-card-text class="agent-detail-copy pa-1">
                        <i
                          class="mdi mdi-email pr-1"
                          aria-hidden="true"
                        /> agent@email.com
                      </v-card-text>
                      <v-card-text class="agent-detail-copy pa-1">
                        <i
                          class="mdi mdi-web pr-1"
                          aria-hidden="true"
                        />website/agentname
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-card>
              </template>
            </v-hover>
          </v-col>
        </v-row>
      </v-container> -->
      <!-- <v-row class="justify-center">
        <v-col
          cols="11"
        >
          <h2 class="provider-heading">
            <i
              class="mdi mdi-handshake"
              aria-hidden="true"
            />
            Financial Lenders
          </h2>
        </v-col>
      </v-row> -->
      <!-- <v-container
        class="d-flex flex-wrap justify-center my-3"
      >
        <v-row class="d-flex flex-wrap pa-2 justify-center">
          <v-col
            v-for="n in 3"
            :key="n"
            class="d-flex flex-wrap pa-2 justify-center"
            cols="12"
            lg="4"
            md="6"
            sm="12"
          >
            <v-card
              class="mx-auto"
              min-height="365px"
              max-width="350px"
              elevation="10"
              color="white"
            >
              <v-img
                height="200px"
                src="https://www.wealthmanagement.com/sites/wealthmanagement.com/files/styles/article_featured_standard/public/loan-money-transfer-illo-getty.jpg?itok=hJp6TaK7"
              />
              <div class="lender-copy">
                <v-card-title class="detail-copy pb-1">
                  Company name
                </v-card-title>
                <v-card-text class="detail-copy pt-0 pl-4">
                  Company address
                </v-card-text>
                <v-card-text class="detail-copy pt-0 pl-4">
                  <i
                    class="mdi mdi-phone pr-1"
                    aria-hidden="true"
                  />Company phone
                </v-card-text>
                <v-card-text class="detail-copy pt-0 pl-4">
                  <i
                    class="mdi mdi-email pr-1"
                    aria-hidden="true"
                  />Company email
                </v-card-text>
                <v-card-text class="detail-copy pt-0 pl-4 pb-0">
                  <i
                    class="mdi mdi-web pr-1"
                    aria-hidden="true"
                  />
                  Company website
                </v-card-text>

                <v-card-text class="pt-0">

                </v-card-text>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container> -->
      <!-- <v-row class="justify-center">
        <v-col
          cols="11"
        >
          <h2 class="provider-heading">
            <i
              class="mdi mdi-hard-hat"
              aria-hidden="true"
            />
            General Contractors
          </h2>
        </v-col>
      </v-row> -->
      <!-- <v-container class="d-flex flex-wrap justify-center my-3">
        <v-row class="d-flex flex-wrap justify-center">
          <v-col
            v-for="n in 3"
            :key="n"
            class="d-flex flex-wrap justify-center"
            cols="12"
            lg="4"
            md="6"
            sm="12"
          >
            <v-card
              class="mx-auto"
              min-height="365px"
              max-width="355px"
              elevation="10"
              color="white"
            >
              <v-row>
                <v-col>
                  <v-card-title class="pt-1">
                    Contractor/Company name
                  </v-card-title>
                  <v-card-subtitle class="pb-8">
                    Address
                  </v-card-subtitle>
                  <div class="contractor-copy">
                    <v-card-text class="detail-copy pt-1 pl-4">
                      <i
                        class="mdi mdi-phone pr-1"
                        aria-hidden="true"
                      />
                      Phone
                    </v-card-text>
                    <v-card-text class="detail-copy pt-0 pl-4">
                      <i
                        class="mdi mdi-email pr-1"
                        aria-hidden="true"
                      />
                      Email
                    </v-card-text>
                    <v-card-text class="detail-copy pt-0 pl-4 pb-0">
                      <i
                        class="mdi mdi-web pr-1"
                        aria-hidden="true"
                      />
                      Website
                    </v-card-text>
                  </div>
                </v-col>
                <v-col>
                  <v-img
                    height="200px"
                    src="https://previews.123rf.com/images/stockstudio44/stockstudio441805/stockstudio44180500085/101297793-construction-and-renovation-concept-building-contractor-tools-on-wooden-table-top-view-.jpg"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="px-1">
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container> -->
      <!-- <v-row
        class="justify-center"
      >
        <v-col
          cols="11"
        >
          <h2 class="provider-heading">
            <i
              class="mdi mdi-diversify"
              aria-hidden="true"
            />
            Misc
          </h2>
        </v-col>
      </v-row>
      <v-container>
        <v-row class="d-flex flex-wrap pa-2 justify-center">
          <v-col
            v-for="n in 3"
            :key="n"
            class="d-flex flex-wrap pa-2 justify-center"
            cols="12"
            lg="4"
            md="6"
            sm="12"
          >
            <v-card
              class="pa-1"
              elevation="10"
              color="white"
              min-height="170px"
              max-width="375px"
            >
              <v-row>
                <v-col
                  cols="6"
                >
                  <v-img
                    height="150px"
                    src="https://buildfire.com/wp-content/uploads/2016/11/realtor-advertising-strategies-1200x900.jpg"
                  />
                </v-col>
                <v-col
                  cols="6"
                  class="pa-0"
                >
                  <div class="misc-copy">
                    <v-card-title class="pt-1 pl-1">
                      Misc. business name
                    </v-card-title>
                    <v-card-subtitle class="pt-0 pl-1 pb-1">
                      Address
                    </v-card-subtitle>
                    <div class="misc-copy">
                      <v-card-text class="detail-copy pt-1 pl-1">
                        <i
                          class="mdi mdi-phone pr-1"
                          aria-hidden="true"
                        />
                        Phone
                      </v-card-text>
                      <v-card-text class="detail-copy pt-0 pl-1">
                        <i
                          class="mdi mdi-email pr-1"
                          aria-hidden="true"
                        />
                        Email
                      </v-card-text>
                      <v-card-text class="detail-copy pt-0 pl-1 pb-0">
                        <i
                          class="mdi mdi-web pr-1"
                          aria-hidden="true"
                        />
                        Website
                      </v-card-text>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container> -->
    </v-container>
  </div>
</template>

<script>
  import axios from 'axios'
  export default {
    name: 'ServiceProviders',
    props: {
      forecast: Object,
      user: Object,
    },
    data: () => ({
      isSuccess: false,
      isFailure: false,
      dialog: false,
      firstName: '',
      firstNameRules: [
        v => !!v || 'First Name is required',
      ],
      lastName: '',
      lastNameRules: [
        v => !!v || 'Last Name is required',
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      phone: '',
      phoneRules: [
        v => !!v || 'Phone number is required',
      ],
      serviceProvided: '',
      serviceRules: [
        v => !!v || 'Please choose an option',
      ],
    }),
    methods: {
      toggleDialog () {
        this.dialog = !this.dialog
      },
      validate () {
        this.$refs.form.validate()
      },
      isNumber: function (evt) {
        var charCode = (evt.which) ? evt.which : evt.keyCode
        if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
          evt.preventDefault()
        } else {
          return true
        }
      },
      submitAgent () {
        const params = {
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          phone_number: this.phone,
          service: this.serviceProvided,
        }
        axios.post('https://api.honely.com/lookup/agent_information', params)
          .then(() => {
            this.isSuccess = true
            setTimeout(() => {
              this.toggleDialog()
            }, 2000)
          }).catch(() => {
            this.isFailure = true
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
#forecast-band-9{
  background-color: #eeeeee;
}
#service-providers {
  color: #07871c;
  border-bottom: 2px solid #07871c;
}
.provider-copy{
  font-size: 20px;
  font-weight: bold;
}
.provider-network-btn{
    font-size: 15px;
    background: #07871c;
    color: #ffffff !important;
    padding: 0 10px;
    margin-left: 10px;
    border-radius: 3px;
    border: 1px solid #07871c;
    i{
        padding-right: 5px;
        font-size: 1.5rem;
    }
}
.forecast-band-header{
  color: $color-emerald;
  border-bottom: 2px solid $color-emerald;
}
.provider-modal-heading{
   color: #07871c;
}
.provider-heading{
        display: flex;
        align-items: center;
        font-weight: 400;
        color: #07871c;
        i{
            font-size: 2.5rem;
            margin-right: 5px;
        }
    }
.agent-photo{
    border-radius: 50% !important;
    border: 1px solid black;
}
.agent-details{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .agent-detail-copy{
        display: flex;
        align-items: center;
        gap: 3px;
        color: black;
        font-weight: 400;
    }
}
.agent-detail-copy, .lender-copy, .contractor-copy, .misc-copy{
    i{
     font-size: 1.2rem;
     color: #00a650;
    }
}
.detail-copy{
    display: flex;
    align-items: center;
    gap: 3px;
}
.contractor-copy{
    height: 100%;
    margin-top: -25px;
}

@media (max-width: 768px)  {
  .provider-CTA{
    text-align: center !important;
    padding: 0 !important;
  }
}
@media (max-width: 500px) {
  .provider-network-btn{
    margin-left: 0;
    padding: 10px !important;
  }
}
</style>

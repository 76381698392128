<template>
  <base-section id="ListingNotFound">
    <!-- <base-heading title="ListingNotFound" /> -->

    <!-- <base-subheading
      class="primary--text"
      title="Oh!"
    /> -->

    <base-subheading
      space="8"
      title="Sorry. This listing has been removed"
    />

    <base-img
      :src="'https://www.honely.com/img/logo.4bb82f35.svg'"
      class="mx-auto mb-12"
      contain
      height="350"
      width="250"
    />

    <!-- <div class="text-center">
      <base-btn :to="{ name: 'Home' }">
        Get me Out of Here
      </base-btn>
    </div> -->
  </base-section>
</template>

<script>
  export default {
    name: 'ListingNotFound',

    provide: {
      heading: { align: 'center' },
    },
  }
</script>

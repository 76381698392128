<template>
  <base-section
    id="info-alt"
    space="56"
  >
    <v-container>
      <v-row justify="space-between">
        <v-col
          cols="12"
          md="5"
        >
          <base-business-info title="Keep In Touch With Us" />
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <base-contact-form title="Get in contact with a Honely Associate" />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'ContactUs',
  }
</script>
<style lang="sass">
  #info-alt
    h3
      text-transform: none !important
      font-weight: 400 !important
      font-size: 24px !important
      line-height: 32px !important

</style>

<template>
<!-- vx: If the contents of this file are modified, the pdf version in s3 bucket needs to be updated too.. -->
  <v-theme-provider light>
    <base-section
      id="html-privacy-content"
      space="40"
    >
      <!-- eslint-disable -->
      <v-container class="privacy-container">
        <h1>PRIVACY POLICY</h1>
        <p>Effective date: January 10, 2022</p>
        <p>This Privacy Policy describes Honely, LLC&rsquo;s policies and procedures on the collection, use and disclosure of Your personal information (PI)and/or personal data (PD) when You access and use our website, <a href="https://www.honely.com">www.honely.com</a> (&ldquo;SITE&rdquo;) to avail Yourself of Our Service provided through the SITE. It describes in detail Your privacy rights and how the law protects You.</p>
        <p>We use Your PI to provide and improve our Services. By accessing and using one or more of the Service, You agree to the collection and use of Your PI in accordance with this Privacy Policy.</p>
        <p>California residents and European Union residents who access the SITE should be sure to also review all sections pertinent to them.</p>
        <p>The following index of the topics covered in this Privacy Policy is presented for your convenience: (1) Interpretation and Definitions; (2) Collecting and Using Your Personal Data; (3) Information on the Processing of Your Personal Data; (4) GDPR Privacy for European Union residents; (5) CCPA Privacy for California residents; (6) Do Not Track Policy as Required by the California On-line Privacy Protection Act; (7) Your California Privacy Rights; (8) California Privacy Rights for Minors; (9) Links to Third Party Websites; (10) Changes to this Privacy Policy; and (11) How to Contact Us.</p>
        <h2>1. Interpretation and Definitions</h2><br>
        <h3>Interpretation</h3>
        <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
        <h3>Definitions</h3>
        <p>For the purposes of this Privacy Policy:</p>
        <ul>
          <li><b>Account</b> means a unique account created for You to access our Service or parts of our Service as a Registered User or Client under our <a href="https://www.honely.com/terms">Terms of Use Agreement</a>.</li>
          <li><b>Business</b>, for the purpose of the CCPA (California Consumer Privacy Act), refers to the Company as the legal entity that collects Consumers&#39; personal information or personal data and determines the purposes and means of the processing of a Consumers&#39; personal information, or on behalf of which such information is collected and that alone, or jointly with others, determines the purposes and means of the processing of consumers&#39; personal information, that does business in the State of California.</li>
          <li><b>CCPA or the California Consumer Privacy Act</b> refers to privacy laws enacted by the State of California to protect the privacy of its residents. The CCPA includes stronger privacy protection provisions that may not be followed by other jurisdictions. Since our Website and Services may be accessed by or utilized by California residents or businesses, our Privacy Policy covers Our obligations under the CCPA. California residents should be sure to review the section of this Privacy Policy specific for California residents.</li>
          <li><b>Company</b> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to Honely, LLC, a Delaware Corporation with a business address of 150 E Palmetto Park Road, Suite 800, Boca Raton, Florida 33432</li>
          <li><b>Consumer</b>, for the purpose of the CCPA (California Consumer Privacy Act), means a natural person who is a California resident. A resident, as defined in the law, includes (1) every individual who is in the USA for other than a temporary or transitory purpose, and (2) every individual who is domiciled in the USA who is outside the USA for a temporary or transitory purpose.</li>
          <li><b>Cookies</b> are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</li>
          <li><b>Country</b> refers to: Florida, United States of America.</li>
          <li><b>Data Controller</b>, for the purposes of the GDPR (General Data Protection Regulation), refers to the Company as the legal person which alone or jointly with others determines the purposes and means of the processing of Personal Data.</li>
          <li><b>Device</b> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</li>
          <li><b>Do Not Track (DNT)</b> is a concept that has been promoted by US regulatory authorities, in particular the U.S. Federal Trade Commission (FTC), for the Internet industry to develop and implement a mechanism for allowing internet users to control the tracking of their online activities across websites.</li>
          <li><b>General Data Protection Act (GDPR)</b> was enacted by the European Union in 2018 to protect the personal data of its residents. Since individuals or businesses located in the European Union may access our Website and utilize our Services, our Privacy Policy covers our obligations under the GDPR. Residents and businesses located in the European Union should be sure to review the section of this Privacy Policy specific for them.</li>
          <li><b>Personal Data</b> is any information that relates to an identified or identifiable individual. This Policy uses Personal Data and Personal Information interchangeably. For the purposes for GDPR, Personal Data means any information relating to You such as a name, an identification number, location data, online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity. For the purposes of the CCPA, Personal Data means any information that identifies, relates to, describes or is capable of being associated with, or could reasonably be linked, directly or indirectly, with You.</li>
          <li><b>Sale</b> of Personal Data/Information for the purpose of the CCPA (California Consumer Privacy Act), means selling, renting, releasing, disclosing, disseminating, making available, transferring, or otherwise communicating orally, in writing, or by electronic or other means, a Consumer&#39;s Personal Data/Information to another business or a third party for monetary or other valuable consideration.</li>
          <li><b>Service(s)</b> refers to the Website and the Services provided by Us directly or indirectly through the Website.</li>
          <li><b>Service Provider</b> means any natural or legal person who processes the Personal Data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used. For the purpose of the GDPR, Service Providers are considered Data Processors.</li>
          <li><b>Third-party Social Media Service</b> refers to any website or any social network website through which a User can log in or create an account to access our Website and use our Services.</li>
          <li><b>Usage Data</b> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a webpage visit).</li>
          <li><b>We, Our, and Us</b> means Honely, LLC.</li>
          <li><b>Website and Site</b> refer to Honely, LLC, accessible <a href="https://www.honely.com">https://www.honely.com</a>.</li>
          <li><b>You and Your</b> means the individual accessing or using the Website, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable. Under GDPR (General Data Protection Regulation), <b>You</b> can be referred to as the Data Subject or as the User as you are the individual using the Service.</li>
          <div style="text-align: center; border: 1px solid black;">
            <h4>IMPORTANT NOTICE</h4>
            <p><b>This Site is being monitored by one or more third-party monitoring software(s), and may capture information about Your visit that will help Us improve the quality of Our services. You may opt-out from the data that SmartPiXL is collecting on Your visit through a universal consumer options page located at <a href="https://smart-pixl.com/Unsub/unsub.html">https://smart-pixl.com/Unsub/unsub.html</a>.</b></p>
          </div>
        </ul>
        <br>
        <h2>2. Collecting and Using Your Personal Data</h2>
        <br>
        <h3>(a) Types of Data Collected</h3>
        <br>
        <h4>Personal Data</h4>
        <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information (PII) that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
        <ul>
          <li>Email address</li>
          <li>First name and last name</li>
          <li>Phone number</li>
          <li>Address, State, Province, ZIP/Postal code, City</li>
          <li>Bank account information in order to pay for products and/or services within the Service</li>
          <li>Usage Data</li>
        </ul>
        <p>When You pay for a product and/or a service via bank transfer, We may ask You to provide information to facilitate this transaction and to verify Your identity. Such information may include, without limitation:</p>
        <ul>
          <li>Date of birth</li>
          <li>Passport or National ID card</li>
          <li>Bank card statement</li>
          <li>Other information linking You to an address</li>
        </ul>
        <h4>Usage Data</h4>
        <p>Usage Data is collected automatically when using the Website. Usage Data may include information such as Your Device&#39;s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
        <p>When You access the Website by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device. You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</p>
        <p>We may also collect information that Your browser sends whenever You visit our Website or when You access the Website by or through a mobile device.</p>
        <h4>Information from Third-Party Social Media Services</h4>
        <p>The Company allows You to create an account and log in to use the Service through the following Third-party Social Media Services:</p>
        <ul>
          <li>Google</li>
          <li>Facebook</li>
          <li>Instagram</li>
          <li>Twitter</li>
        </ul>
        <p>If You decide to register through or otherwise grant us access to a Third-Party Social Media Service, We may collect Personal data that is already associated with Your Third-Party Social Media Service&#39;s account, such as Your name, Your email address, Your activities or Your contact list associated with that account.</p>
        <p>You may also have the option of sharing additional information with the Company through Your Third-Party Social Media Service&#39;s account. If You choose to provide such information and Personal Data, during registration or otherwise, You are giving the Company permission to use, share, and store it in a manner consistent with this Privacy Policy.</p>
        <h3>(b) Tracking Technologies and Cookies</h3>
        <p>We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:</p>
        <ul>
          <li><b>Cookies or Browser Cookies.</b> A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.</li>
          <li>Flash Cookies. Certain features of our Service may use local stored objects (or Flash Cookies) to collect and store information about Your preferences or Your activity on our Service. Flash Cookies are not managed by the same browser settings as those used for Browser Cookies. For more information on how You can delete Flash Cookies, please read &quot;Where can I change the settings for disabling, or deleting local shared objects?&quot; available at <a href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_">https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_</a></li>
          <li><b>Web Beacons.</b> Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).</li>
        </ul>
        <p>Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser.</p>
        <p>We use both Session and Persistent Cookies for the purposes set out below:</p>
        <ul>
          <li>
            Necessary / Essential Cookies
            <ul>
              <li>Type: Session Cookies</li>
              <li>Administered by: Us</li>
              <li>Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.</li>
            </ul>
          </li>
          <li>
            Cookies Policy / Notice Acceptance Cookies
            <ul>
              <li>Type: Persistent Cookies</li>
              <li>Administered by: Us</li>
              <li>Purpose: These Cookies identify if users have accepted the use of cookies on the Website.</li>
            </ul>
          </li>
          <li>
            Functionality Cookies
            <ul>
              <li>Type: Persistent Cookies</li>
              <li>Administered by: Us</li>
              <li>Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.</li>
            </ul>
          </li>
          <li>
            Tracking and Performance Cookies
            <ul>
              <li>Type: Persistent Cookies</li>
              <li>Administered by: Third-Parties</li>
              <li>Purpose: These Cookies are used to track information about traffic to the Website and how users use the Website. The information gathered via these Cookies may directly or indirectly identify you as an individual visitor. This is because the information collected is typically linked to a pseudonymous identifier associated with the device you use to access the Website. We may also use these Cookies to test new pages, features or new functionality of the Website to see how our users react to them.</li>
            </ul>
          </li>
        </ul>
        <p>For more information about the cookies we use and your choices regarding cookies, please visit our <a href="https://www.honely.com/cookiepolicy">Cookies Policy</a>.</p>
        <p>The Company may use Personal Data for the following purposes:</p>
        <ul>
          <li><b>To provide and maintain our Service</b>, including to monitor the usage of our Service.</li>
          <li><b>To manage Your Account:</b> to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</li>
          <li><b>For the performance of a contract:</b> the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.</li>
          <li><b>To contact You:</b> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application&#39;s push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</li>
          <li><b>To provide You</b> with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.</li>
          <li><b>To manage Your requests:</b> To attend and manage Your requests to Us.</li>
          <li><b>For business transfers:</b> We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.</li>
          <li><b>For other purposes:</b> We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.</li>
        </ul>
        <p>We may share Your personal information in the following situations:</p>
        <ul>
          <li><b>With Service Providers:</b> We may share Your personal information with Service Providers to monitor and analyze the use of our Service, for payment processing, to contact You.</li>
          <li><b>For business transfers:</b> We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.</li>
          <li><b>With Affiliates:</b> We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.</li>
          <li><b>With business partners:</b> We may share Your information with Our business partners to offer You certain products, services or promotions.</li>
          <li><b>Payment partners:</b> If you pay for our Service(s) by credit or debit card, your credit or debit card information, you will be notified that you are leaving our website. In that event, you will be taken to a third-party payment management service which We have retained to collect payments from our Users. You should review the privacy policies of any third-party payment management service. We will not collect or retain Your credit or debit card information.</li>
          <li><b>With other users:</b> when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with other users or register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service may see Your name, profile, pictures and description of Your activity. Similarly, other users will be able to view descriptions of Your activity, communicate with You and view Your profile.</li>
          <li><b>With Your consent:</b> We may disclose Your personal information for any other purpose with Your consent.</li>
        </ul>
        <h3>(c) Retention of your personal data</h3>
        <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
        <p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>
        <h3>(d) Transfer of Your Personal Data</h3>
        <p>Your information, including Personal Data, is processed at the Company&#39;s operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers or servers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction. Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</p>
        <p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</p>
        <h3>(e) Disclosure of Your Personal Data</h3>
        <h4>Business Transactions</h4>
        <p>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>
        <h4>Law enforcement</h4>
        <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
        <h4>Other legal requirements</h4>
        <p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
        <ul>
          <li>Comply with a legal obligation</li>
          <li>Protect and defend the rights or property of the Company</li>
          <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
          <li>Protect the personal safety of Users of the Service or the public</li>
          <li>Protect against legal liability</li>
        </ul>
        <h3>Security of Your Personal Data</h3>
        <p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>
        <h2>(3) Information on the Processing of Your Personal Data</h2>
        <p>Service Providers such as IT professionals have access to Your Personal Data only to perform their tasks on Our behalf and are obligated not to disclose or use it for any other purpose via Agreement between them and Us.</p>
        <h3>Analytics</h3>
        <p>We may use third-party Service providers to monitor and analyze the use of our Service.</p>
        <ul>
          <li>
            Google Analytics
            <ul>
              <li>Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising network.</li>
              <li>You can opt-out of having made your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js and dc.js) from sharing information with Google Analytics about visits activity. For more information on the privacy practices of Google, please visit the Google Privacy &amp; Terms web page: <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a></li>
            </ul>
          </li>
        </ul>
        <h3>Payments</h3>
        <p>We may provide paid products and/or services within the Service. In that case, we may use third-party services for payment processing (e.g. payment processors).</p>
        <p>We will not store or collect Your payment card details. That information is provided directly to Our third-party payment processors whose use of Your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.</p>
        <h3>BNC</h3>
        <p>Their Privacy Policy can be viewed at <a href="https://www.bncnationalbank/privacy policy">www.bncnationalbank/privacy policy</a></p>
        <p>When You use Our Service to pay a product and/or service via bank transfer, We may ask You to provide information to facilitate this transaction and to verify Your identity.</p>
        <h2>(4) GDPR Privacy</h2>
        <h3>Legal Basis for Processing Personal Data under GDPR</h3>
        <p>We may process Personal Data under the following conditions:</p>
        <ul>
          <li><b>Consent:</b> You have given Your consent for processing Personal Data for one or more specific purposes.</li>
          <li><b>Performance of a contract:</b> Provision of Personal Data is necessary for the performance of an agreement with You and/or for any pre-contractual obligations thereof.</li>
          <li><b>Legal obligations:</b> Processing Personal Data is necessary for compliance with a legal obligation to which the Company is subject.</li>
          <li><b>Vital interests:</b> Processing Personal Data is necessary in order to protect Your vital interests or of another natural person.</li>
          <li><b>Public interests:</b> Processing Personal Data is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Company.</li>
          <li><b>Legitimate interests:</b> Processing Personal Data is necessary for the purposes of the legitimate interests pursued by the Company.</li>
        </ul>
        <p>In any case, the Company will gladly help to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Data is a statutory or contractual requirement, or a requirement necessary to enter into a contract.</p>
        <h3>Your Rights under the GDPR</h3>
        <p>The Company undertakes to respect the confidentiality of Your Personal Data and to guarantee You can exercise Your rights.</p>
        <p>You have the right under this Privacy Policy, and by law if You are within the EU, to:</p>
        <ul>
          <li><b>Request Access to Your Personal Data.</b> The right to access, update or delete the information We have on You. Whenever made possible, you can access, update or request deletion of Your Personal Data directly within Your account settings section. If you are unable to perform these actions yourself, please contact Us to assist You. This also enables You to receive a copy of the Personal Data We hold about You.</li>
          <li><b>Request Correction of the Personal Data that We Hold About You.</b> You have the right to have any incomplete or inaccurate information We hold about You corrected.</li>
          <li><b>Object to processing of Your Personal Data.</b> This right exists where We are relying on a legitimate interest as the legal basis for Our processing and there is something about Your particular situation, which makes You want to object to our processing of Your Personal Data on this ground. You also have the right to object where We are processing Your Personal Data for direct marketing purposes.</li>
          <li><b>Request Erasure of Your Personal Data.</b> You have the right to ask Us to delete or remove Personal Data when there is no good reason for Us to continue processing it.</li>
          <li><b>Request the Transfer of Your Personal Data.</b> We will provide to You, or to a third-party You have chosen, Your Personal Data in a structured, commonly used, machine-readable format. Please note that this right only applies to automated information which You initially provided consent for Us to use or where We used the information to perform a contract with You.</li>
          <li><b>Withdraw Your consent.</b> You have the right to withdraw Your consent on using your Personal Data. If You withdraw Your consent, We may not be able to provide You with access to certain specific functionalities of Our Service.</li>
        </ul>
        <h3>Exercising of Your GDPR Data Protection Rights</h3>
        <p>You may exercise Your rights of access, rectification, cancellation and opposition by contacting Us using one or both of the e-mail addresses provided below. Please note that we may ask You to verify Your identity before responding to such requests. If You make a request, We will try our best to respond to You as soon as possible.</p>
        <ul>
          <li><a href="mailto:info@honely.com">Info@Honely.com</a></li>
          <li><a href="mailto:contact@honely.com">Contact@Honely.com</a></li>
        </ul>
        <p>You have the right to complain to a Data Protection Authority about Our collection and use of Your Personal Data. For more information, if You are in the European Economic Area (EEA), please contact Your local data protection authority in the EEA.</p>
        <h2>(5) CCPA Privacy</h2>
        <p>This section is specific for California residents and supplements the information contained in Our Privacy Policy and it applies solely to all visitors, users, and others who reside in the State of California.</p>
        <h3>Categories of Personal Information Collected</h3>
        <p>We collect information that identifies, relates to, describes, references, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular Consumer or Device. The following is a list of categories of personal information which we may collect or may have been collected from California residents within the last twelve (12) months.</p>
        <p>Please note that the categories and examples provided in the list below are those defined in the CCPA. This does not mean that all examples of that category of personal information were in fact collected by Us, but reflects our good faith belief to the best of our knowledge that some of that information from the applicable category may be and may have been collected. For example, certain categories of personal information would only be collected if You provided such personal information directly to Us.</p>
        <ul>
          <li>
            Category A: Identifiers.
            <ul>
              <li>Examples: A real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, driver&#39;s license number, passport number, or other similar identifiers.</li>
              <li>Collected: Yes.</li>
            </ul>
          </li>
          <li>
            Category B: Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e)).
            <ul>
              <li>Examples: A name, signature, Social Security number, physical characteristics or description, address, telephone number, passport number, driver&#39;s license or state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information. Some personal information included in this category may overlap with other categories.</li>
              <li>Collected: Yes for credit card number or debit card number. No for the other listed examples.</li>
            </ul>
          </li>
          <li>
            Category C: Protected classification characteristics under California or federal law.
            <ul>
              <li>Examples: Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex (including gender, gender identity, gender expression, pregnancy or childbirth and related medical conditions), sexual orientation, veteran or military status, genetic information (including familial genetic information).</li>
              <li>Collected: No except user may have option to specify an age range, e.g., 40-49.</li>
            </ul>
          </li>
          <li>
            Category D: Commercial information.
            <ul>
              <li>Examples: Records and history of products or services purchased or considered at the Site.</li>
              <li>Collected: Yes.</li>
            </ul>
          </li>
          <li>
            Category E: Biometric information.
            <ul>
              <li>Examples: Genetic, physiological, behavioral, and biological characteristics, or activity patterns used to extract a template or other identifier or identifying information, such as, fingerprints, faceprints, and voiceprints, iris or retina scans, keystroke, gait, or other physical patterns, and sleep, health, or exercise data.</li>
              <li>Collected: No</li>
            </ul>
          </li>
          <li>
            Category F: Internet or other similar network activity.
            <ul>
              <li>Examples: Interaction with our Services or advertisements.</li>
              <li>Collected: Yes.</li>
            </ul>
          </li>
          <li>
            Category G: Geolocation data.
            <ul>
              <li>Examples: Approximate physical location.</li>
              <li>Collected: Yes.</li>
            </ul>
          </li>
          <li>
            Category H: Sensory data.
            <ul>
              <li>Examples: Audio, electronic, visual, thermal, olfactory, or similar information.</li>
              <li>Collected: No.</li>
            </ul>
          </li>
          <li>
            Category I: Professional or employment-related information.
            <ul>
              <li>Examples: Current or past job history or performance evaluations.</li>
              <li>Collected: No, except Realtors, as Registered Users who hold themselves out to be a realtor may optionally provide their license number and state of licensure which is public information anyway.</li>
            </ul>
          </li>
          <li>
            Category J: Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).
            <ul>
              <li>Examples: Education records directly related to a student maintained by an educational institution or party acting on its behalf, such as grades, transcripts, class lists, student schedules, student identification codes, student financial information, or student disciplinary records.</li>
              <li>Collected: No.</li>
            </ul>
          </li>
          <li>
            Category K: Inferences drawn from other personal information.
            <ul>
              <li>Examples: Profile reflecting a person&#39;s preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.</li>
              <li>Collected: No.</li>
            </ul>
          </li>
        </ul>
        <p>Under CCPA, personal information does not include:</p>
        <ul>
          <li>Publicly available information from government records</li>
          <li>Deidentified or aggregated consumer information</li>
          <li>Information excluded from the CCPA&#39;s scope, such as:
            <ul>
              <li>Health or medical information covered by the Health Insurance Portability and Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or clinical trial data</li>
              <li>Personal Information covered by certain sector-specific privacy laws, including the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act (FIPA), and the Driver&#39;s Privacy Protection Act of 1994</li>
            </ul>
          </li>
        </ul>
        <h3>Sources of Personal Information</h3>
        <p>We obtain the categories of personal information listed above from the following categories of sources:</p>
        <ul>
          <li><b>Directly from You.</b> For example, from the forms You complete on our Service, preferences You express or provide through our Service, or from Your purchases on our Service.</li>
          <li><b>Indirectly from You.</b> For example, from observing Your activity on our Service.</li>
          <li><b>Automatically from You.</b> For example, through cookies We or our Service Providers set on Your Device as You navigate through our Service.</li>
          <li><b>From Service Providers.</b> For example, third-party vendors to monitor and analyze the use of our Service, third-party vendors for payment processing, or other third-party vendors that We use to provide the Service to You.</li>
        </ul>
        <h3>Use of Personal Information for Business Purposes or Commercial Purposes</h3>
        <p>We may use or disclose personal information We collect for &quot;business purposes&quot; or &quot;commercial purposes&quot; (as defined under the CCPA), which may include the following examples:</p>
        <ul>
          <li>To operate our Services and provide You with our Services.</li>
          <li>To provide You with support and to respond to Your inquiries, including to investigate and address Your concerns and monitor and improve our Service.</li>
          <li>To fulfill or meet the reason You provided the information. For example, if You share Your contact information to ask a question about our Service, We will use that personal information to respond to Your inquiry. If You provide Your personal information to purchase a product or service, We will use that information to process Your payment and facilitate delivery.</li>
          <li>To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.</li>
          <li>As described to You when collecting Your personal information or as otherwise set forth in the CCPA.</li>
          <li>For internal administrative and auditing purposes.</li>
          <li>To detect security incidents and protect against malicious, deceptive, fraudulent or illegal activity, including, when necessary, to prosecute those responsible for such activities.</li>
        </ul>
        <p>Please note that the examples provided above are illustrative and not intended to be exhaustive. For more details on how we use this information, please refer to the &quot;Use of Your Personal Data&quot; section.</p>
        <p>If We decide to collect additional categories of personal information or use the personal information We collected for materially different, unrelated, or incompatible purposes We will update this Privacy Policy.</p>
        <h3>Disclosure of Personal Information for Business Purposes or Commercial Purposes</h3>
        <p>We may use or disclose and may have used or disclosed in the last twelve (12) months the following categories of personal information for business or commercial purposes:</p>
        <ul>
          <li>Category A: Identifiers</li>
          <li>Category B: Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e))</li>
          <li>Category D: Commercial information</li>
          <li>Category F: Internet or other similar network activity</li>
        </ul>
        <p>Please note that the categories listed above are those defined in the CCPA. This does not mean that all examples of that category of personal information were in fact disclosed, but reflects our good faith belief to the best of our knowledge that some of that information from the applicable category may be and may have been disclosed.</p>
        <p>When We disclose personal information for a business purpose or a commercial purpose, We enter a contract that describes the purpose and requires the recipient to both keep that personal information confidential and not use it for any purpose except performing the contract.</p>
        <h3>Sale of Personal Information</h3>
        <p>As defined in the CCPA, &quot;sell&quot; and &quot;sale&quot; mean selling, renting, releasing, disclosing, disseminating, making available, transferring, or otherwise communicating orally, in writing, or by electronic or other means, a consumer&#39;s personal information by the business to a third party for valuable consideration. This means that We may have received some kind of benefit in return for sharing personal Information, but not necessarily a monetary benefit.</p>
        <p>Please note that the categories listed below are those defined in the CCPA. This does not mean that all examples of that category of personal information were in fact sold, but reflects our good faith belief to the best of our knowledge that some of that information from the applicable category may be and may have been shared for value in return.</p>
        <p>We may sell and may have sold in the last twelve (12) months the following categories of personal information:</p>
        <ul>
          <li>Category A: Identifiers</li>
          <li>Category B: Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e))</li>
          <li>Category D: Commercial information</li>
          <li>Category E: Internet or other similar network activity.</li>
        </ul>
        <h3>Share of Personal Information</h3>
        <p>We may share Your personal information identified in the above categories with the following categories of third parties:</p>
        <ul>
          <li>Service Providers</li>
          <li>Payment processors</li>
          <li>Our affiliates</li>
          <li>Our business partners</li>
          <li>Third party vendors to whom You or Your agents authorize Us to disclose Your personal information in connection with products or services We provide to You</li>
        </ul>
        <h3>Sale of Personal Information of Minors Under 16 Years of Age</h3>
        <p>Per Our Terms of Use, the Users of Our Site and its Services must be at least 18 years of age. This provision is presented in the event someone who accesses and uses the Site misrepresents that they are at least 18 years of age. We do not sell the personal information of Consumers We actually know are less than 16 years of age, unless We receive affirmative authorization (the &quot;right to opt-in&quot;) from either the Consumer who is between 13 and 16 years of age, or the parent or guardian of a Consumer less than 13 years of age. Consumers who opt-in to the sale of personal information may opt-out of future sales at any time. To exercise the right to opt-out, You (or Your authorized representative) may submit a request to Us by contacting Us.</p>
        <p>If You have reason to believe that a child under the age of 13 (or 16) has provided Us with personal information, please contact Us with sufficient detail to enable Us to delete that information.</p>
        <h3>Your Rights Under the CCPA</h3>
        <p>The CCPA provides California residents with specific rights regarding their personal information. If You are a resident of California, You have the following rights:</p>
        <ul>
          <li><u>The Right to Notice.</u> You have the right to be notified which categories of Personal Data are being collected and the purposes for which the Personal Data is being used.</li>
          <li>
            <u>The Right to Request.</u> Under the CCPA, You have the right to request that We disclose information to You about Our collection, use, sale, disclosure for business purposes and share of personal information. Once We receive and confirm Your request, We will disclose to You:
            <ul>
              <li>The categories of personal information We collected about You</li>
              <li>The categories of sources for the personal information We collected about You</li>
              <li>Our business or commercial purpose for collecting or selling that personal information</li>
              <li>The categories of third parties with whom We share that personal information</li>
              <li>The specific pieces of personal information We collected about You</li>
              <li>
                If we sold Your personal information or disclosed Your personal information for a business purpose, We will disclose to You:
                <ul>
                  <li>The categories of personal information categories sold</li>
                  <li>The categories of personal information categories disclosed</li>
                </ul>
              </li>
            </ul>
          </li>
          <li><u>The Right to Say No to the Sale of Personal Data (Opt-Out)</u>. You have the right to direct Us to not sell Your personal information. To submit an opt-out request please contact Us at <a href="mailto:info@honely.com">info@honely.com</a>.</li>
          <li>
            <u>The Right to Delete Personal Data.</u> You have the right to request the deletion of Your Personal Data, subject to certain exceptions. Once We receive and confirm Your request, We will delete (and direct Our Service Providers to delete) Your personal information from our records, unless an exception applies. We may deny Your deletion request if retaining the information is necessary for Us or Our Service Providers to:
            <ul>
              <li>Complete the transaction for which We collected the personal information, provide a good or service that You requested, take actions reasonably anticipated within the context of our ongoing business relationship with You, or otherwise perform our contract with You.</li>
              <li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.</li>
              <li>Debug products to identify and repair errors that impair existing intended functionality.</li>
              <li>Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law.</li>
              <li>Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 et. seq.).</li>
              <li>Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information&#39;s deletion may likely render impossible or seriously impair the research&#39;s achievement, if You previously provided informed consent.</li>
              <li>Enable solely internal uses that are reasonably aligned with consumer expectations based on Your relationship with Us.</li>
              <li>Comply with a legal obligation.</li>
              <li>Make other internal and lawful uses of that information that are compatible with the context in which You provided it.</li>
            </ul>
          </li>
          <li>
            <u>The Right not to be Discriminated Against.</u> You have the right not to be discriminated against for exercising any of Your consumer&#39;s rights, including by:
            <ul>
              <li>Denying goods or services to You</li>
              <li>Charging different prices or rates for goods or services, including the use of discounts or other benefits or imposing penalties</li>
              <li>Providing a different level or quality of goods or services to You</li>
              <li>Suggesting that You will receive a different price or rate for goods or services or a different level or quality of goods or services</li>
            </ul>
          </li>
        </ul>
        <h3>Exercising Your CCPA Data Protection Rights</h3>
        <p>In order to exercise any of Your rights under the CCPA, and if You are a California resident, You can contact Us:</p>
        <ul>
          <li>By email: <a href="mailto:info@honely.com">Info@honely.com</a></li>
          <li>By visiting our contact page on our website: <a href="https://www.honely.com/contact">https://www.honely.com/contact</a></li>
        </ul>
        <p>Only You, or a person registered with the California Secretary of State that You authorize to act on Your behalf, may make a verifiable request related to Your personal information.</p>
        <p>Your request to Us must:</p>
        <ul>
          <li>Provide sufficient information that allows Us to reasonably verify You are the person about whom We collected personal information or an authorized representative</li>
          <li>Describe Your request with sufficient detail that allows Us to properly understand, evaluate, and respond to it</li>
        </ul>
        <p>We cannot respond to Your request or provide You with the required information if we cannot:</p>
        <ul>
          <li>Verify Your identity or authority to make the request</li>
          <li>And confirm that the personal information relates to You</li>
        </ul>
        <p>We will disclose and deliver the required information free of charge within 45 days of receiving Your request once your information is verified. The time period to provide the required information may be extended once by an additional 45 days when reasonable necessary and with prior notice.</p>
        <p>Any disclosures We provide will only cover the 12-month period preceding the verifiable request&#39;s receipt.</p>
        <p>For data portability requests, We will select a format to provide Your personal information that is readily useable and should allow You to transmit the information from one entity to another entity without hindrance.</p>
        <h3>Do Not Sell My Personal Information</h3>
        <p>You have the right to opt-out of the sale of Your personal information. Once We receive and confirm a verifiable consumer request from You, we will stop selling Your personal information. To exercise Your right to opt-out, please contact Us.</p>
        <p>The Service Providers we partner with (for example, our analytics or advertising partners) may use technology on the Service that sells personal information as defined by the CCPA law. If you wish to opt out of the use of Your personal information for interest-based advertising purposes and these potential sales as defined under CCPA law, you may do so by following the instructions below.</p>
        <p>Please note that any opt out is specific to the browser You use. You may need to opt out on every browser that You use.</p>
        <h4>Website</h4>
        <p>You can opt out of receiving ads that are personalized as served by our Service Providers by following our instructions presented on the Service:</p>
        <ul>
          <li>The NAI&#39;s opt-out platform: <a href="https://www.networkadvertising.org/choices">https://www.networkadvertising.org/choices/</a></li>
          <li>The EDAA&#39;s opt-out platform <a href="https://www.youronlinechoices.com/">http://www.youronlinechoices.com/</a></li>
          <li>The DAA&#39;s opt-out platform: <a href="https://optout.aboutads.info/?c=2&amp;lang=EN">http://optout.aboutads.info/?c=2&amp;lang=EN</a></li>
        </ul>
        <p>The opt out will place a cookie on Your computer that is unique to the browser You use to opt out. If you change browsers or delete the cookies saved by your browser, You will need to opt out again.</p>
        <h4>Mobile Devices</h4>
        <p>Your mobile device may give You the ability to opt out of the use of information about the apps You use in order to serve You ads that are targeted to Your interests:</p>
        <ul>
          <li>&quot;Opt out of Interest-Based Ads&quot; or &quot;Opt out of Ads Personalization&quot; on Android devices</li>
          <li>&quot;Limit Ad Tracking&quot; on iOS devices</li>
        </ul>
        <p>You can also stop the collection of location information from Your mobile device by changing the preferences on Your mobile device.</p>
        <h2>(6) &quot;Do Not Track&quot; Policy as Required by the California Online Privacy Protection Act (CalOPPA)</h2>
        <p>Our Service does not respond to Do Not Track signals.</p>
        <p>However, some third-party websites do keep track of Your browsing activities. If You are visiting such websites, You can set Your preferences in Your web browser to inform websites that You do not want to be tracked. You can enable or disable DNT by visiting the preferences or settings page of Your web browser.</p>
        <h2>(7) Your California Privacy Rights (California&#39;s Shine the Light law)</h2>
        <p>Under California Civil Code Section 1798 (California&#39;s Shine the Light law), California residents with an established business relationship with us can request information once a year about sharing their Personal Data with third parties for the third parties&#39; direct marketing purposes.</p>
        <p>If you&#39;d like to request more information under the California Shine the Light law, and if You are a California resident, You can contact Us using the contact information provided below.</p>
        <h2>(8) California Privacy Rights for Minor Users (California Business and Professions Code Section 22581)</h2>
        <p>California Business and Professions Code section 22581 allow California residents under the age of 18 who are registered users of online sites, services or applications to request and obtain removal of content or information they have publicly posted.</p>
        <p>To request removal of such data, and if You are a California resident, You can contact Us using the contact information provided below, and include the email address associated with Your account.</p>
        <p>Be aware that Your request does not guarantee complete or comprehensive removal of content or information posted online and that the law may not permit or require removal in certain circumstances.</p>
        <h2>(9) Links to Other Websites</h2>
        <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party&#39;s site. We strongly advise You to review the Privacy Policy of every site You visit.</p>
        <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
        <h2>(10) Changes to this Privacy Policy</h2>
        <p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>
        <p>We will let You know by prominent notice on Site, prior to the change becoming effective and update the &quot;Last updated&quot; date at the top of this Privacy Policy.</p>
        <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
        <h2>(11) How to Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, You can contact us:</p>
        <p>By email:</p>
        <ul>
          <li><a href="mailto:info@honely.com">Info@Honely.com</a></li>
          <li><a href="mailto:contact@honely.com">Contact@Honely.com</a></li>
        </ul>
      </v-container>
      <!-- eslint-disable -->
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'PrivacyContent',
    provide: {
      theme: { isDark: true },
    },
    data: () => ({
      leftHeader: 'Groupings of Individual Data We Amass',
      rightHeader: 'Usage of Individual Data',
      rightColumn: 'Providing support and services examining and enhancing our business personally customizable content and involvements Advertising, marketing, and promotional determinations Safeguarding and defending our business Protecting our legal privileges Auditing, reporting, corporate authority, and internal operations Conforming with legal requirements',
      tableFooterHeader: 'Individual rights.',
      tableFooterDescription: 'Please see Your Privileges and Choices in Section 10 beneath for a description of the options we offer and the privileges you have concerning your private information. If you are a California resident, please be sure to examine Section 15 below titled "Additional Information for Residents in Certain Jurisdictions" for vital info about the categories of personal info we gather and release and your privileges below California privacy laws.',
      leftColumnItems: [
        {
          id: 1,
          heading: 'Name, contact info and additional identifiers',
          description: 'such as name, contact info, IP address and online identifiers, Social Security number and additional government identifiers.',
        },
        {
          id: 2,
          heading: 'Customer archives',
          description: 'paper and electronic archives encompassing individual data, comprising without restriction, name, signature, photo, contact data, education and employment history, Social Security number and other government identifiers, insurance policy number, financial or expense data, medical data, or health insurance data.',
        },
        {
          id: 3,
          heading: 'Secured classifications',
          description: 'characteristics of protected classifications below applicable state or federal law, such as race, color, sex, age, religion, national origin, disability, citizenship status, and hereditary data.',
        },
        {
          id: 4,
          heading: 'Commercial Data',
          description: 'considered, or additional buying or usage histories or affinities.',
        },
        {
          id: 5,
          heading: 'Use information',
          description: 'internet or additional electronic network goings-on Data as well as without restriction browsing history, search history, and data concerning an person’s interface with our Services or an ad.',
        },
        {
          id: 6,
          heading: 'Geolocation information',
          description: 'specific location data concerning a specific person or device.',
        },
        {
          id: 7,
          heading: 'Audio, video, and other electronic data',
          description: 'such as, video film, photos, call recordings, and additional audio, electronic, pictorial, or alike data.',
        },
        {
          id: 8,
          heading: 'Employment past',
          description: 'professional or employment-correlated data.',
        },
        {
          id: 9,
          heading: 'Education info',
          description: 'education data and archives.',
        },
        {
          id: 10,
          heading: 'Profiles and inferences',
          description: 'inferences drawn from any of the above used to make a profile showing a person’s preferences, behaviors or other features.',
        },
      ],
      categoryHeaderOne: 'Categories of private data',
      categoryHeaderTwo: 'Do we gather?',
      categoryHeaderThree: 'Do we release for business determinations?',
      categoryLastUpdated: 'November 20, 2020',
      categoryItems: [
        {
          id: 1,
          catHeading: 'Name, contact data and additional identifiers',
          catDescription: 'identifiers such as a actual name, alias, address, unique private identifier, online identifier, Internet Protocol (IP) address, email address, account name, social security number, driver’s license number, passport number, or additional comparable identifiers.',
          catGather: 'Yes',
          catRelease: 'Yes',
        },
        {
          id: 2,
          catHeading: 'Client archives',
          catDescription: 'paper and electronic client accounts encompassing individual data, comprising without restriction, name, signature, physical features or details, address, telephone number, education, current employment, employment history, social security number, passport number, driver’s license or state identification card number, insurance policy number, bank account number, credit card number, debit card number, or any additional financial or payment data, medical data, or health insurance data.',
          catGather: 'Yes',
          catRelease: 'Yes',
        },
        {
          id: 3,
          catHeading: 'Protected classifications',
          catDescription: 'features of protected classifications below appropriate state or federal law like race, color, sex, age, religion, national origin, disability, citizenship status, and hereditary data.',
          catGather: 'Yes',
          catRelease: 'Yes',
        },
        {
          id: 4,
          catHeading: 'Commercial Data',
          catDescription: 'comprising records of real estate, products or services bought, acquired, or considered, or additional purchasing or usage histories or tendencies.',
          catGather: 'Yes',
          catRelease: 'Yes',
        },
        {
          id: 5,
          catHeading: 'Data use',
          catDescription: 'internet or additional electronic network action Data comprising without restriction browsing history, search history, and data about a consumer’s interaction with an Internet website, application, or ad.',
          catGather: 'Yes',
          catRelease: 'Yes',
        },
        {
          id: 6,
          catHeading: 'Geolocation data',
          catDescription: 'accurate geographic location data regarding a certain person or device.',
          catGather: 'Yes',
          catRelease: 'Yes',
        },
        {
          id: 7,
          catHeading: 'Audio, Video and additional Electronic Information',
          catDescription: 'audio, electronic, visual, thermal, olfactory, or comparable data like, CCTV footage, photographs, and call recordings.',
          catGather: 'Yes',
          catRelease: 'Yes',
        },
        {
          id: 8,
          catHeading: 'Employment records',
          catDescription: 'professional or employment-affiliated data.',
          catGather: 'Yes',
          catRelease: 'Yes',
        },
        {
          id: 9,
          catHeading: 'Education data',
          catDescription: 'education data that is not publicly accessible individually identifiable data as defined in the federal Family Educational Rights and Privacy Act (20 U.S.C. section 1232g, 34 C.F.R. Part 99).',
          catGather: 'Yes',
          catRelease: 'Yes',
        },
        {
          id: 10,
          catHeading: 'Profiles and inferences',
          catDescription: 'Inferences drawn from any of the data identified overhead to make a profile reflecting a resident’s inclinations, characteristics, psychological tendencies, predispositions, behavior, attitudes, intelligence, abilities, or aptitudes.',
          catGather: 'Yes',
          catRelease: 'Yes',
        },
      ],
    }),
  }
</script>

<style lang="sass">
  #html-privacy-content
    p, li
      font-family: $body-font-family
      span
        font-weight: 600
        color: black
    ul
      margin-bottom: 10px
    .gray-med
      color: $color-gray-med
    .privacy-table
      border: 1px solid $color-gray-lt
      border-bottom: none
      font-family: $body-font-family
      .left-column
        border-right: 1px solid $color-gray-lt
      span
        font-weight: 600
        // font-style: italic
    .privacy-table:last-child
      border-bottom: 1px solid $color-gray-lt
    .table-content.v-card, #privacy-table-footer
      border-bottom: 1px solid $color-gray-lt
    .table-content.v-card:last-child
      border-bottom: none
    .yes-no.yn-two
      border-left: 1px solid $color-gray-lt
    .individual-data
      .bottom-row
        border-bottom: 1px solid $color-gray-lt
    ul.bold-copy>li
      font-weight: 600
      text-transform: uppercase
      span
        font-style: italic
      ul, p
        font-weight: 400
        text-transform: none
    ul.li-spaced>li
      margin-bottom: 10px
</style>

<style>
.privacy-container {
}
.page-title {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 3rem !important;
}
.section-title {
  font-size: 22px;
  font-weight: bold;
  margin: 3rem 0 1rem 0 !important;
  border-bottom: 1px solid #00a650;
}
.content-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px !important;
}
.content-sub-title {
  font-weight: bold;
  margin-bottom: 5px !important;
}
.subsection-title {
  font-size: 18px;
  font-weight: bold;
  margin: 2rem 0 1rem 0 !important;
}
.copy-bold {
  font-weight: bold;
}
.copy-links {
}
.copy-notes {
  text-decoration: underline;
}
.list-no-bullet {
  margin-bottom: 1.5rem !important;
}
.list-normal {
  margin-bottom: 1.5rem !important;
}
.list-normal-sub {
}
.list-normal-sub-sub {
}
</style>

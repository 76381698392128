import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#00a650',
        secondary: '#999999',
        accent: '#e67e22',
        error: '#e74c3c',
      },
      dark: {
        primary: '#00a650',
        secondary: '#999999',
        accent: '#e67e22',
      },
    },
  },
})

<template>
  <v-theme-provider dark>
    <base-section
      id="icon-band"
      class="primary green-gradient"
      space="40"
    >
      <v-container
        class="background-shapes"
      >
        <v-row
          justify="center"
        >
          <v-col
            v-for="item in items"
            :key="item.id"
            cols="12"
            sm="6"
            md="3"
            class="align-center text-center"
          >
            <v-list-item three-line>
              <v-list-item-content>
                <i
                  :class="'pt-1 mb-5 mdi band-header-icon ' + item.iconclass"
                  aria-hidden="true"
                />
                <v-list-item-title
                  class="icon-headline mb-1"
                  v-text="item.headline"
                />
                <v-list-item-subtitle
                  class="mt-3"
                  v-html="item.copy"
                />
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionContactUs',

    data: () => ({
      items: [
        { id: 1, headline: 'Growth', copy: 'Locate a home positioned for growth in just a few clicks.', iconclass: 'mdi-chart-line' },
        { id: 2, headline: 'True Value', copy: 'Discover the true value of your home or potential investment.', iconclass: 'mdi-currency-usd' },
        { id: 3, headline: 'Data Science', copy: 'Leverage industry leading data science and predictive analytics.', iconclass: 'mdi-database-marker' },
        { id: 4, headline: 'Filter Your Search', copy: 'Filter by zip code, property specifications and forecasted growth rates', iconclass: 'mdi-map-search-outline' },
      ],
    }),

  }
</script>

<style lang="sass">
  #icon-band
    background: linear-gradient(to right, #018f45 , #3bc950)
    padding: 70px 0 85px 0 !important

    .v-list-item__content > .band-header-icon
      display: block !important
      font-size: 50px
      background-color: rgba(255,255,255,.4)
      flex: 0 0 auto
      margin: 0 auto
      width: 76px
      height: 76px
      border-radius: 38px

    .icon-headline
      font-family: $heading-font-family
      font-size: 20px
      line-height: 25px
      font-weight: 600

    .v-list-item__content > .band-header-icon:before
      vertical-align: middle

    .v-list-item__subtitle
      color: white !important
      font-size: 18px !important
      line-height: 22px !important
      font-weight: 400
      overflow: visible !important
      text-overflow: clip !important
      -webkit-line-clamp: 4
</style>

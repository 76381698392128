import WebFontLoader from 'webfontloader'

// async load fonts
WebFontLoader.load({
  google: {
    families: [
      'PT+Sans:400,700&display=swap',
      'Work+Sans:100,300,400,500,700,900&display=swap',
    ],
  },
})

WebFontLoader.WebFontConfig = {
  custom: {
    families: ['itc_avant_garde_gothicmedium', 'itc_avant_garde_gothicMdOb', 'itc_avant_garde_gothicbold', 'itc_avant_garde_gothicBdOb'],
    urls: ['/public/fonts.css'],
  },
}

<template>
  <v-theme-provider light>
    <base-section
      id="insights"
      space="40"
    >
      <v-container id="insights-container">
        <v-row
          justify="start"
          class="border-me-not"
        >
          <v-col cols="12">
            <v-card
              v-if="firstItem"
              class="mx-auto featured-insight rounded-lg"
              max-width="1200"
              elevation="6"
              :height="$vuetify.breakpoint.xs ? 'auto' : 'auto'"
              :to="'/insights' + firstItem.path"
            >
              <v-img
                :src="firstItem.image"
                max-width="455"
                max-height="330"
              />
              <div class="insight-content">
                <v-card-subtitle>
                  Posted: {{ firstItem.post_date.slice(0,-13) }}
                </v-card-subtitle>
                <v-card-title v-html="firstItem.title" />
                <v-card-text>
                  {{ firstItem.truncatedBody }}
                </v-card-text>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row
          v-if="filteredItems"
          id="insights-grid"
          ref="content"
          justify="start"
          class="border-me-not flex-wrap justify-center"
        >
          <v-col
            v-for="item in filteredItems"
            :key="item.id"
            cols="12"
            sm="8"
            md="4"
          >
            <v-card
              :to="'/insights' + item.path"
              class="mx-auto rounded-lg standard-insight"
              min-height="365"
              elevation="4"
            >
              <v-img
                :src="item.image"
                height="200px"
              />
              <div class="insight-content">
                <v-card-subtitle>
                  Posted: {{ item.post_date.slice(0,-13) }}
                </v-card-subtitle>
                <v-card-title>
                  {{ item.title }}
                </v-card-title>
                <v-card-text>
                  {{ item.truncatedBody }}
                </v-card-text>
              </div>
            </v-card>
          </v-col>
        </v-row>

        <v-col
          class="mt-5 chevron-container"
          cols="12"
        >
          <v-row justify="center">
            <v-icon
              id="chevron"
              @click="expandPosts"
            >
              {{ "mdi-chevron-double-down" }}
            </v-icon>
          </v-row>
        </v-col>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'SectionInsights',
    data () {
      return {
        page: 1,
        postsDisplayed: 10,
      }
    },
    computed: {
      ...mapGetters('insights', {
        posts: 'allPosts',
        total: 'totalPosts',
      }),
      limit () {
        if (this.page === 1) {
          return 10
        } else {
          return 9
        }
      },
      offset () {
        if (this.page === 1) {
          return 0
        } else {
          return 10 + 9 * (this.page - 2)
        }
      },
      firstItem () {
        if (this.page === 1) {
          var firstItem = this.posts.length > 0 ? this.posts[0] : null
          if (firstItem !== null) {
            var truncatedBody = firstItem.body
              ? firstItem.body.substring(
                  firstItem.body.indexOf('<p>') + 3,
                  firstItem.body.indexOf('</p>'),
                ) + '..'
              : ''
            if (truncatedBody.length > 250) {
              truncatedBody = truncatedBody.substring(0, 250) + '...'
            }
            firstItem.truncatedBody = truncatedBody
            firstItem.image = firstItem.image_filename
            var blogdate = new Date(firstItem.post_date)
            blogdate.setDate(blogdate.getDate() + 1)
            var year = blogdate.getFullYear()
            var month = blogdate.getMonth() + 1
            var date = blogdate.getDate()
            firstItem.path = `/${year}/${month}/${date}/${firstItem.slug}/`
          }
          return firstItem
          // return this.posts.length > 0 ? this.posts[0] : null
        } else {
          return null
        }
      },
      filteredItems () {
        var filteredItems = []
        if (this.page === 1) {
          for (let i = 1; i < this.postsDisplayed; i++) {
            var filteredItem = this.posts[i]
            if (filteredItem) {
              filteredItem.truncatedBody =
                this.posts[i].body.substring(
                  this.posts[i].body.indexOf('<p>') + 3,
                  this.posts[i].body.indexOf('</p>'),
                ) + '..'
              if (filteredItem.truncatedBody.length > 250) {
                filteredItem.truncatedBody =
                  filteredItem.truncatedBody.substring(0, 250) + '...'
              }
              filteredItem.image = this.posts[i].image_filename
              var year = new Date(filteredItem.post_date).getFullYear()
              var month = new Date(filteredItem.post_date).getMonth()
              var date = new Date(filteredItem.post_date).getDate()
              filteredItem.path = `/${year}/${month}/${date}/${filteredItem.slug}/`
              filteredItems.push(filteredItem)
            }
          }
          return filteredItems
          // return this.posts.filter((item, index) => {
          //   return index > 0 && index < 10
          // })
        } else {
          return this.posts
        }
      },
      totalPages () {
        if (this.total - 10 > 0) {
          return Math.ceil(1 + (this.total - 10) / 9)
        } else {
          return 0
        }
      },
    },

    created () {
      this.load()
    },

    methods: {
      load () {
        this.$store.dispatch('insights/getPosts', { all: 'yes' })
      },
      onPageChange () {
        this.load()
      },
      convertFirstLetterEachWordToUpperCase (value) {
        var words = value.split(' ')
        return words
          .map((word) => {
            return word[0].toUpperCase() + word.substring(1)
          })
          .join(' ')
      },
      // scrollInsight (direction) {
      //   const content = this.$refs.content
      //   if (direction === 'prev') {
      //     content.scrollLeft -= 600
      //   } else if (direction === 'next') {
      //     content.scrollLeft += 600
      //   }
      // },
      expandPosts () {
        if (this.postsDisplayed >= this.posts.length) {
          document.getElementById('chevron').style.display = 'none'
        } else {
          this.postsDisplayed = this.postsDisplayed + 9
        }
      },
    },
  }
</script>

<style lang="sass">
#home-cards
  background: #aaaaaa url('~@/assets/bg-housetops.png') no-repeat 60% 75px

#home-cards h1
  font-family: $heading-font-family
  font-weight: normal
  font-size: 35px

#home-cards p
  font-family: $heading-font-family
  font-weight: normal
  font-size: 20px
  line-height: 25px

#home-cards .v-sheet.v-card
  box-shadow: 4px 4px 14px #666666
  border-radius: 5px
  border: 1px solid white

.camera-icon
  top: 158px
  left: 8px
  background-color: rgba(0,0,0,.5)
  color: rgba(255, 255, 255, .7) !important

.camera-icon:hover
  color: #ffffff !important

.heart-icon
  bottom: 5px
  right: 8px
  z-index: 3
  color: $color-gray-lt !important

.heart-icon:hover
  background-color: transparent
  color: $color-squash !important

#homecontainer
  display: flex
  justify-content: space-between

.card-pricing
  display: block
  flex-basis: auto
  font-size: 23px
  font-weight: normal
  padding: 8px
  line-height: 21px

.card-pricing span.pricecat
  font-size: 14px
  white-space: nowrap
  letter-spacing: 0

.price2
  color: $color-emerald

.card-attributes
  display: block
  flex-basis: auto
  margin-top: 10px !important
  padding: 8px
  font-family: $body-font-family
  font-size: 13px
  text-align: right
  color: #000000 !important

.card-attributes span
  font-size: 10px
  color: $color-gray-lt
  letter-spacing: 0

.card-attributes b
  color: $color-squash

.addressinfo
  padding: 0 0 0 12px
  color: black !important
  font-size: 14px
  line-height: 16px

.addressinfo span
  font-family: $body-font-family
  color: $color-gray-mddk
  font-size: 12px

.cardbtns
  padding: 4px

.centeritems
  text-align: center

.icon-left-space
  margin-left: 5px
  margin-right: -5px

.icon-rotate
  transform: rotate(90deg)

.icon-green
  color: $color-emerald !important
  font-size: 22px !important
  line-height: 22px !important

.btn-lightgray
  border: 1px solid white !important
  border-radius: 5px !important
  margin-top: 10px
  background-color: $color-gray-mdlt !important
  height: 40px !important
  width: 215px !important

.btn-lightgray span.v-btn__content
  color: #000000 !important
  font-size: 18px
  font-weight: 600

.btn-lightgray:hover span.v-btn__content
  color: $color-emerald !important

.btn-lightgray:hover
  background-color: $color-gray-lt !important

.theme--light .intro-text h1, .theme--light .intro-text p
  color: $color-gray-shadow

#insights-container
  max-width: 1185px !important

.insight-content
  overflow: hidden

.insight-content .v-card__subtitle
  color: $color-gray-med
  font-size: 15px
  line-height: 17px
  padding-bottom: 8px

.insight-content .v-card__title
  font-family: $heading-font-family
  font-weight: normal
  font-size: 18px
  line-height: 25px
  word-break: normal
  padding-top: 0

.standard-insight
  height: 100% !important

.standard-insight .v-image
  border-radius: 5px 5px 0 0

.featured-insight, .standard-insight
  border: 1px solid white

.featured-insight
  display: flex !important

.featured-insight .v-image
  border-radius: 5px 0 0 8px !important
  flex-grow: 1
  flex-shrink: 0

.featured-insight .insight-content
  padding: 20px 40px
  flex-grow: 2
  overflow: hidden

.featured-insight .insight-content .v-card__subtitle
  border-bottom: 4px solid $color-emerald
  padding: 0 0 6px 0

.featured-insight .insight-content .v-card__title
  font-size: 22px
  line-height: 28px
  margin: 10px 0 !important
  padding: 0

.featured-insight .insight-content .v-card__text
  padding: 0
  margin: 0
  font-size: 18px
  line-height: 25px

.mdi-chevron-left::before, .mdi-chevron-right::before
  font-size: 3em !important
  cursor: pointer

#insights-grid
   scroll-behavior: smooth

#chevron
  font-size: 4em
@media only screen and (max-width: 600px)
  .featured-insight
    display: block
    .v-image
      border-radius: 5px 5px 0 0 !important
  #insights-grid
    flex-wrap: wrap !important
    justify-content: center !important
  .chevron-container
    display: none
</style>

<style>
@media (max-width: 900px) {
  .featured-insight {
    flex-wrap: wrap;
  }
  .featured-insight .v-image {
    max-width: initial !important;
    max-height: initial !important;
    width: 100%;
  }
  .featured-insight .insight-content {
    padding: 20px;
  }
}
</style>

<template>
  <v-card
    class="mx-auto home-card"
    light
    style="min-height: 415px; height: 100%"
  >
    <router-link
      style="text-decoration: none; color: inherit;"
      :to="{ name: 'ListingId', params: listingParams, query: queryParams}"
    >
      <v-img
        v-if="img !== placeHolder"
        id="propertyImg"
        :src="img"
        height="200px"
      />
      <v-img
        v-if="img === placeHolder"
        id="propertyImg"
        :src="img"
        height="200px"
        contain
      />

      <div class="homecontainer">
        <v-card-title
          class="card-pricing pa-2"
        >
          <!-- <template v-if="((item.for_sale === 'Active') || (item.for_sale === 'For Sale') || (item.for_sale === 'PENDING') || (item.for_sale === 'Contingent')) && (item.list_price)">
          ${{ formatPrice(item.list_price) }}
          <span class="pricecat">
            list
          </span><br>
        </template> -->
          <!-- <template v-else-if="item.appraisal">
          ${{ formatPrice(item.appraisal) }}
          <span class="pricecat">
            appraisal
          </span><br>
        </template>
        <template v-else>
          <br>
        </template> -->

          <span
            class="price2"
          >
            <!-- ${{item}} -->
            <span v-if="item.appraisal">${{ formatPrice(item.appraisal) }}</span>
            <span
            v-else
            style="display: inline-block;"
            >No Valuation
              <v-tooltip
                  content-class="custom-tooltip"
                  bottom
                  max-width="300px"
                >
                  <template #activator="{ on, attrs }">
                    <i
                      :class="'pt-1 mb-5 mdi band-header-icon mdi-information'"
                      aria-hidden="true"
                      style="float:right;color:black;font-size:13px;"
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <span>Our proprietary automated valuation model provides the fairest value estimate on the market and quickly adjusts to unusual market conditions.<br><br>Our models have been back tested and verified by a third party to ensure integrity and prevent cherry picking.</span>
                </v-tooltip>
            </span>
            <br>
            <span class="pricecat">
              <span v-if="item.appraisal">Honely Value Estimate</span>
              <span v-if="item.appraisal">
                <v-tooltip
                  content-class="custom-tooltip"
                  bottom
                  max-width="300px"
                >
                  <template #activator="{ on, attrs }">
                    <i
                      :class="'pt-1 mb-5 mdi band-header-icon mdi-information'"
                      aria-hidden="true"
                      style="float:right;color:black;font-size:13px;"
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <span>Our proprietary automated valuation model provides the fairest value estimate on the market and quickly adjusts to unusual market conditions.<br><br>Our models have been back tested and verified by a third party to ensure integrity and prevent cherry picking.</span>
                </v-tooltip>
              </span>
            </span>
          </span>
        </v-card-title>

        <v-card-subtitle
          class="card-attributes mt-0  pa-2 font-weight-regular"
        >
          <!-- <template v-if="item.beds_count"> -->
          <template>
            <b style="color: black;">{{ formatCount(item.beds_count) }} bds</b>
            <span>•</span>
          </template>
          <!-- <template v-if="item.baths"> -->
          <template>
            <b style="color: black;">{{ formatCount(item.baths) }} ba</b>
            <span>•</span>
          </template>
          <template v-if="item.total_area_sq_ft">
            <b style="color: black;">{{ formatCountInt(item.total_area_sq_ft) }}&nbsp;sqft</b>
          </template>
          <br>
          <b>{{ item.propstatus }}</b>
        </v-card-subtitle>
      </div>
      <!-- <span class="price2 pricecat">
      Honely Value Estimate
    </span> -->
      <v-card-text
        class="addressinfo"
      >
        <span style="color: black; font-size: 15px;">
          <!-- vx: pikachu -->
          <!-- <b>{{ item.full_address }}</b> -->
          <!-- <b>{{ item.formatted_street_address }} {{ item.city }} {{ item.state }} {{ item.zip_code }}</b> -->
          <b>{{ item.full_address }}</b>
        </span>
        <span v-if="item.realtor">
          {{ item.realtor }}
        </span>
        <!-- <template v-if="item.comparable_score">
        <br><br>
        <span><b>Comparable Score:</b></span> <span>{{ giveInt(item.comparable_score) }}</span>
      </template> -->
        <template
          v-if="item.comparable_score"
          style="margin-bottom: 20 px;"
        >
          <br>
          <span
            style="float: left; margin-top: 10px; margin-right: 10px; color: black; font-size: 18px;"
          >
            <b>Comparable Score:</b>
          </span>
          <span
            v-if="item.comparable_score >=75"
            class="circle-green"
          >
            {{ giveInt(item.comparable_score) }}
          </span>
          <span
            v-if="item.comparable_score <75 && item.comparable_score >=50"
            class="circle-amber"
          >
            {{ giveInt(item.comparable_score) }}
          </span>
          <span
            v-if="item.comparable_score <50 && item.comparable_score >0"
            class="circle-red"
          >
            {{ giveInt(item.comparable_score) }}
          </span>
          <br>
        <br>
        </template>
        <!-- <span> -->
        <template>
          <div
          style="margin-top:4px"
          >
          <span
            v-if="item && item.property_type"
            style="float: left; margin-right: 10px"
          >
            Home type: {{ item.ptype }}
          </span>
          <br>
          <span
            v-if="item.for_sale && item.for_sale !== 'Fail'"
            style="float: left; margin-right: 10px"
          >
            Status: <span style="color: #07871c; display: inline">{{ item.for_sale }}</span>
          </span>
          <span
            v-if="item.for_sale && item.for_sale === 'Fail'"
            style="float: left; margin-right: 10px"
          >
            Status: <span style="color: #e74c3c; display: inline">Unknown</span>
          </span>
          <span
            v-if="!item.for_sale"
            style="float: left; margin-right: 10px"
          >
            Status: <span style="color: #e74c3c; display: inline">Unknown</span>
          </span>
          <span
            v-if="item.fainitiallistdate "
            style="margin-right: 10px"
          >
            Property posted on: {{ item.fainitiallistdate }}
          </span>
          <span
            v-if="!item.fainitiallistdate "
            style="margin-right: 10px"
          >
            Property posted on: Date Unavailable
          </span>
          </div>
        </template>
        <!-- </span> -->
        <p
          class="home-card-link-button"
        >
          <!-- <router-link :to="{ name: 'Listing', params: { fips: item.fips, apn: item.apn }, query: { address: query }}"> -->
          <router-link :to="{ name: 'ListingId', params: listingParams, query: queryParams}">
            <!-- <v-btn
            text
            plain
            class="mx-3 pa-0"
          >
            View details
            <i
              class="mdi mdi-play"
              aria-hidden="true"
            />
          </v-btn> -->
            <button class="btn-view-details">
              <i class="fas fa-home" />
              View Property Details
            </button>
          </router-link>
          <router-link
            v-if="showEdit"
            :to="{ name: 'EditListing', params: { fips: item.fips, apn: item.apn }}"
          >
            <v-btn
              text
              plain
              class="mx-3 pa-0"
            >
              Edit Listing
              <i
                class="mdi mdi-play"
                aria-hidden="true"
              />
            </v-btn>
          </router-link>

        <!--        <v-btn-->
        <!--          text-->
        <!--          color="#000000"-->
        <!--          plain-->
        <!--          class="ma-0 pa-0"-->
        <!--          @click="showAlert('View more like this');"-->
        <!--        >-->
        <!--          View more like this-->
        <!--          <i-->
        <!--            class="mdi mdi-play"-->
        <!--            aria-hidden="true"-->
        <!--          />-->
        <!--        </v-btn>-->
        </p>
      </v-card-text>
      <v-card-actions
        class="card-buttons"
      >
        <v-btn
          v-if="item.additional_images"
          icon
          absolute
          plain
          class="camera-icon"
          @click="showAlert('View photos')"
        >
          <v-icon>{{ 'mdi-camera' }}</v-icon>
        </v-btn>

        <v-btn
          icon
          absolute
          plain
          class="heart-icon"
          @click.prevent="toggleFavorite(item)"
        >
          <template
            v-if="!isFavorite(item)"
          >
            <v-icon>mdi-heart-outline</v-icon>
          </template>
          <template v-else>
            <v-icon>mdi-heart</v-icon>
          </template>
        </v-btn>
      </v-card-actions>
    </router-link>
    <img :src='listhubTrackerUrl'>
  </v-card>
</template>

<script>
  import axios from 'axios'
  import {
    mapGetters,
  } from 'vuex'
  import store from '@/store'
  import { bus } from '../main'
  import * as constants from './base64constants'

  export default {
    name: 'PropertyListingsCard',

    props: {
      item: Object,
      query: String,
      showEdit: Boolean,
    },
    data () {
      return {
        user: null,
        img: this.getImgBaseUrl(),
        placeHolder: constants.placeHolder,
        listhubTrackerUrl: 'https://tracking.listhub.net/tracker?mp=' + encodeURIComponent('M-5383') + '&ev=' + encodeURIComponent('SEARCH_DISPLAY') + '&et=' + encodeURIComponent(Date.now()) + '&v=' + encodeURIComponent('3') + '&ep=' + encodeURIComponent(window.location.href) + '&lkey=' + encodeURIComponent(this.item.listingkey) + '&clid=' + encodeURIComponent('123456') + '&ua=' + encodeURIComponent(navigator.userAgent),
      }
    },

    computed: {
      ...mapGetters('listings', ['favoriteListings']),
      ...mapGetters('auth', ['loggedIn', 'username', 'vxAuth', 'vxAuthDependent', 'isCognitoUserLoggedIn', 'cognitoUser']),
      listingParams () {
        if (this.item.listingkey) {
          return {
            // listing_key: this.item.listingkey,
            // property_id: this.item.property_id,
            // property_id: this.item.property_id,
            search_id: this.item.listingkey,
          }
        } else {
          return {
            // property_id: this.item.property_id,
            search_id: this.item.property_id,
          }
        }
      },
      queryParams () {
        if (this.item.listingkey) {
          return {
            listing_key: this.item.listingkey,
            address: this.query,
          }
        } else {
          return {
            address: this.query,
          }
        }
      },
    },
    updated () {
      this.getImage(this.item)
    },
    mounted () {
      this.getImage()
      if (this.$store.getters['auth/isCognitoUserLoggedIn']) {
        axios.get('https://api.honely.com/lookup/user_profile', {
          params: {
            email: this.$store.getters['auth/cognitoUser'].attributes.email,
          },
        }).then((response) => {
          this.user = response.data
          // console.log(this.user)
        }).catch((error) => {
          console.log('[ERROR] Failed to fetch user data', error)
          console.log(error.response.data.errorMessage)
        })
      }
      // console.log('vx: this.favoriteListings', this.favoriteListings)
      // axios.get('https://tracking.listhub.net/tracker', {
      //   headers: {
      //     'Access-Control-Allow-Origin': '*',
      //     'Content-Type': 'application/json',
      //     'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
      //     Accept: 'application/json',
      //   },
      //   params: {
      //     mp: encodeURIComponent('M-5383'),
      //     ev: encodeURIComponent('SEARCH_DISPLAY'),
      //     et: encodeURIComponent(Date.now()),
      //     v: encodeURIComponent('3'),
      //     ep: encodeURIComponent(window.location.href),
      //     lkey: encodeURIComponent(this.item.listingkey),
      //     clid: encodeURIComponent('123456'),
      //     ua: encodeURIComponent(navigator.userAgent),
      //   },
      // })
    },
    methods: {
      getImage: function (item) {
        const toDataURL = url => fetch(url)
          .then(response => response.blob())
          .then(blob => new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.onerror = reject
            reader.readAsDataURL(blob)
          }))
        toDataURL(this.getImgBaseUrl())
          .then(dataUrl => {
            if (dataUrl === constants.noImage) {
              this.img = constants.placeHolder
              return
            }
            this.img = this.getImgBaseUrl()
          })
          .catch(() => {
            this.img = this.placeHolder
          })
      },
      getImgBaseUrl () {
        if (this.item.tempImages) {
          return '/apartment_temp_images/' + this.item.tempImages[0]
        } else {
          // console.log('vx: ctrl coming here in getImgBaseUrl..')
          // console.log('vx: this.item.listhubImage', this.item.listhubImage)
          // console.log('vx: this.item.latitude', this.item.latitude)
          // console.log('vx: this.item.longitude', this.item.longitude)
          if (this.item.listhubImage) {
            // console.log('vx: squirt', this.item.listhubImage)
            return this.item.listhubImage
          /* } else if (this.item.listhubImage && img.onerror) {
            // console.log('vx: bulba')
            return this.placeHolder */
          } else {
            // console.log('vx: char')
            if (this.item.listingkey) {
              return this.placeHolder
            } else {
              return 'https://maps.googleapis.com/maps/api/streetview?size=600x400&location=' + this.item.latitude + '' +
                ',' + this.item.longitude + '+&fov=90&source=outdoor&key=' + this.$mapsKey
            }
            // return 'https://maps.googleapis.com/maps/api/streetview?size=600x400&location=' + this.item.latitude + '' +
            //   ',' + this.item.longitude + '+&fov=90&source=outdoor&key=' + this.$mapsKey
          }
        }
      },
      isFavorite: function (listing) {
        // console.log('vx: listing.fromListHub', listing.fromListHub)
        // console.log('vx: listing', listing)
        // console.log('vx: this.favoriteListings', this.favoriteListings)
        const exists = this.favoriteListings.find(function (x) {
          // console.log('vx: x', x)
          if (x.fromListHub) {
            // console.log('vx: x', x)
            return x.listingkey === listing.listingkey
          } else {
            // console.log('vx: x', x)
            return x.property_id === listing.property_id
          }
          // return x.fips === listing.fips && x.apn === listing.apn
        })
        // console.log('vx: exists from card isFavorite', exists)
        return exists
      },
      toggleFavorite: function (listing) {
        // if (this.$store.getters['auth/loggedIn']) {
        //   store.dispatch('listings/toggleFavorite', listing)
        // } else {
        //   bus.$emit('showError', { message: 'Please log in or create an account to add this to your favorites' })
        // }
        // console.log('vx: auth/isCognitoUserLoggedIn in togglefav (Listing.vue) is', this.$store.getters['auth/isCognitoUserLoggedIn'])
        if (this.$store.getters['auth/isCognitoUserLoggedIn']) {
          if (!(!this.isFavorite(listing) && this.favoriteListings.length === 5)) {
            var payload = {
              property_id: null,
              apn: listing.apn,
              fips: listing.fips,
              user_id: this.user.user_id,
              address: listing.full_address,
              appraisal: listing.appraisal,
            }
            if (listing.listingkey) {
              payload.property_id = listing.listingkey
            } else {
              payload.property_id = listing.property_id
            }
            // console.log('vx: payload inside toggleFavorite is', payload)
            this.$store.dispatch('listings/toggleFavorite', payload)
          } else {
            bus.$emit('showError', { message: 'Cannot have more than 5 favorite listings!' })
          }
        } else {
          bus.$emit('showError', { message: 'Please log in or create an account to add this to your favorites' })
        }
      },
      showAlert: function (theAlert = 'Button Clicked') {
        alert(theAlert)
      },
      formatPrice (value) {
        // let val = (value/1).toFixed(2).replace('.', ',')
        if (value) {
          value = value.toString().split('.')[0]
          value = parseInt(value)
          value = (value % 100 >= 50) ? Math.ceil(value / 100) * 100 : Math.floor(value / 100) * 100
          return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        } else {
          return '--'
        }
      },
      formatCount (value) {
        if (!parseFloat(value)) {
          return '--'
        } else {
          return String(parseFloat(value))
        }
      },
      formatCountInt (value) {
        if (!parseInt(value)) {
          return '--'
        } else {
          return String(parseInt(value))
        }
      },
      giveInt (value) {
        return value.toString().split('.')[0]
      },
    },
  }
</script>

<style lang="sass">

.home-card.v-sheet.v-card
  border: 1px solid #07871c
  box-shadow: none

  .homecontainer
    display: flex
    justify-content: space-between

    .card-pricing
      display: block
      flex-basis: auto
      font-size: 23px
      font-weight: 600
      line-height: 21px
      text-align: left

      .pricecat
        font-size: 14px
        white-space: nowrap
        letter-spacing: 0
        margin-left: 3px

      .price2
        color: $color-emerald
        margin-left: 2px

    .card-attributes
      display: block
      flex-basis: auto
      font-family: $body-font-family
      font-size: 13px
      line-height: 21px
      text-align: right
      color: #000000

      span
        font-size: 10px
        color: $color-gray-lt
        letter-spacing: 0

      b
        color: $color-squash

  .addressinfo
    padding: 0 0 0 12px
    color: black
    font-size: 14px
    line-height: 16px
    text-align: left

    span
      display: block
      font-family: $body-font-family
      color: $color-gray-mddk
      font-size: 12px

    .home-card-links
      z-index: 10

      .v-btn__content
        color: black
        letter-spacing: normal
        font-weight: 600

      .v-btn
        i
          color: $color-squash

      .v-btn:first-child
        margin-left: 0 !important

  .card-buttons
    padding: 4px

    .v-btn
      border-radius: 4px

    .camera-icon
      top: 158px
      left: 8px
      background-color: rgba(0,0,0,.5)
      color: rgba(255, 255, 255, .7)

    .camera-icon:hover
      color: #ffffff

    .heart-icon
      bottom: 5px
      right: 8px
      z-index: 3
      color: rgb(255,0,0) !important

    .heart-icon:hover
      background-color: transparent
      color: $color-squash

    .heart-icon.selected
      color: $color-squash

</style>
<style scoped>
.home-card-link-button {
  margin-top: 1rem;
  margin-bottom: 0 !important;
}
.home-card-link-button a {
  text-decoration: none;
}
.btn-view-details {
  font-size: 14px;
  padding: 2px 10px;
  min-height: 50px;
  border: 1px solid #00a650;
  border-radius: 3px;;
}
#search-listings-content .btn-view-details, #forecast-band-6 .btn-view-details {
  padding: 5px 10px;
}
.btn-view-details:hover {
  border-color: #e67e22;
  color: #e67e22;
}
.btn-view-details i {
  margin-right: 2px;
}
.circle-green
    {
    width:40px;
    height:40px;
    border-radius:20px;
    font-size:18px !important;
    color:#fff !important;
    line-height:40px;
    text-align:center;
    background:#2a8c45;
    margin-right: 10px;
    float: right;
    }
.circle-amber
    {
    width:40px;
    height:40px;
    border-radius:20px;
    margin-right: 10px;
    float: right;
    font-size:18px !important;
    color:black !important;
    line-height:40px;
    text-align:center;
    background:#FFBF00
    }
.circle-red
    {
    width:40px;
    height:40px;
    border-radius:20px;
    font-size:18px !important;
    color:#fff !important;
    line-height:40px;
    text-align:center;
    background:red;
    margin-right: 10px;
    float: right;
    }
</style>
<style>
.custom-tooltip {
    opacity: 1!important;
}
</style>
